import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";

const keyList = {
    smsUpdate : 'TRAINING_UPDATE',
    smsDelete : 'TRAINING_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        training_type: [],
        training_type_count: null,
        training_type_without_pg: [],
        training_type_count_without_pg: null,
        trainer:[],
        trainer_count:null,
        trainer_without_pg:[],
        trainer_count_without_pg:null,
        training_list:[],
        my_training_list:[],
        count:null,

        trainingLoading: false,
        trainingError: false,
        trainingsubmitSuccess:false,

        ...store
    },
    
    mutations : {
        ...mutations,

        SET_TRAINING_TYPE (state,  {results,pg_count}) {
            state.training_type = results;
            state.training_type_count = pg_count;
        },

        SET_TRAINER(state, {results,pg_count}){
            state.trainer = results;
            state.trainer_count = pg_count;
        },

        SET_TRAINING_TYPE_WITHOUT_PG (state, results) {
            state.training_type_without_pg = results;
        },

        SET_TRAINER_WITHOUT_PG (state, results){
            state.trainer_without_pg = results;
        },

        SET_TRAINING_LIST(state, {results,pg_count}){
            state.training_list = results;
            state.training_list_Count = pg_count;
        },

        SET_MY_TRAINING_LIST(state, {results,pg_count}){
            state.my_training_list = results;
            state.count = pg_count;
        },

        TRAINING_LOADING (state, loading) {
            state.trainingLoading = loading;
        },

        TRAINING_ERROR (state, loading) {
            state.trainingError = loading;
        },

        TRAINING_SUBMIT_SUCCESS (state, success) {
            state.trainingsubmitSuccess = success;
        }
    },

    actions: {
        //Training Type
        async addTrainingType ({commit, dispatch}, payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_training_type, payload.training_type, config).then(result=>{
                    let data = result.data
                    return data

                }); 

                commit('TRAINING_SUBMIT_SUCCESS', true);
                dispatch ('fetchTrainingType',{perPage:payload.perPage , currentPage:payload.page})
                return response

            }catch (e) {
                commit('TRAINING_ERROR', true);
            }finally {
                commit('TRAINING_LOADING', false);
            }
        },

        async fetchTrainingType ({ commit },payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_training_type+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count} = result.data
                commit('SET_TRAINING_TYPE',  {results,pg_count});

            } catch(error) {
                commit('TRAINING_ERROR', true);
            }
            commit('TRAINING_LOADING', false);
        },
        
        async fetchTrainingTypeWithoutPG ({ commit }) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_training_type_without_pg, config);
                const results = result.data.data
                commit('SET_TRAINING_TYPE_WITHOUT_PG', results);

            } catch(error) {
                commit('TRAINING_ERROR', true);
            }
            commit('TRAINING_LOADING', false);
        },

        async updateTrainingType ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.update_training_type+payload.training_type.id, payload.training_type,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch ('fetchTrainingType',{perPage:payload.perPage , currentPage:payload.page})
                }
            )
            return response
        },

        async deleteTrainigType ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_training_type + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchTrainingType',{perPage:payload.perPage , currentPage:payload.page})
            return response
        },

        async updateTrainingTypeStatus ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.toggle_training_type_status + payload.status, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchTrainingType',{perPage:payload.perPage , currentPage:payload.page})
            return response
        },

        //Trainers
        async addTrainer ({commit, dispatch}, payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_trainer, payload.trainer, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('TRAINING_SUBMIT_SUCCESS', true);
                dispatch ('fetchTrainer', {perPage:payload.perPage, currentPage:payload.currentPage})
                return response

            }catch (e) {
                commit('TRAINING_ERROR', true);
            }finally {
                commit('TRAINING_LOADING', false);
            }
        },

        async fetchTrainer ({ commit },payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_all_trainer+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count} = result.data
                commit('SET_TRAINER', {results,pg_count});

            } catch(error) {
                commit('TRAINING_ERROR', true);
            }
            commit('TRAINING_LOADING', false);
        },

        async fetchTrainerWithoutPG ({ commit },payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_all_trainer_without_pg, config);
                const results = result.data.data
                commit('SET_TRAINER_WITHOUT_PG', results);

            } catch(error) {
                commit('TRAINING_ERROR', true);
            }
            commit('TRAINING_LOADING', false);
        },

        async updateTrainer({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.update_trainer + payload.id,
                        {
                            name: payload.name, 
                            contact_number: payload.contact_number,
                            email: payload.email,
                            description: payload.description,
                            status: payload.status
                        }, config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })
                    dispatch('fetchTrainer', {perPage:payload.perPage, currentPage:payload.currentPage});
                }
            )
            return response
        },

        async deleteTrainer ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_trainer + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchTrainer', {perPage:payload.perPage, currentPage:payload.currentPage});
            return response
        },

        async updateTrainerStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.toggle_trainer_status + payload.value, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchTrainer', {perPage:payload.perPage, currentPage:payload.currentPage});
            return response
        },

        //Training
        async addTraining ({commit, dispatch}, payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);

            let config = { 
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                } 
            };

            try {
                let response = await axios.post(V1API.create_training, payload.training, config).then(result=>{
                    let data = result.data
                    return data
                });
                commit('TRAINING_SUBMIT_SUCCESS', true);
                dispatch ('fetchTraining',{perPage:payload.perPage, currentPage:payload.page, search_term:payload.search_term, start_date:payload.start_date, end_date:payload.end_date, type:payload.type, trainer:payload.trainer}) 
                return response

            }catch (e) {
                commit('TRAINING_ERROR', true);
            }finally {
                commit('TRAINING_LOADING', false);
            }
        },

        async fetchTraining ({ commit },payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.type) params.append('type', payload.type);
                if (payload.trainer) params.append('trainer', payload.trainer);
                
                let url = V1API.get_training_lits + '?' + params.toString();
                let result = await axios.get(url, config);
                const {results, pg_count} = result.data
                commit('SET_TRAINING_LIST', {results, pg_count});

            } catch(error) {
                commit('TRAINING_ERROR', true);
            } finally {
                commit('TRAINING_LOADING', false);
            }
        },

        async updateTraining({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.update_training+payload.id,
                        {
                            training_type: payload.training_type,
                            trainer: payload.trainer,
                            cost:payload.cost,
                            start_date:payload.start_date,
                            end_date:payload.end_date,
                            status:payload.status

                        },config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchTraining',{perPage:payload.perPage, currentPage:payload.page, search_term:payload.search_term, start_date:payload.start_date, end_date:payload.end_date, type:payload.type, trainer:payload.trainer});

                }
            )
            return response
        },

        async deleteTraining ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_training + payload.value, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTraining',{perPage:payload.perPage, currentPage:payload.page, search_term:payload.search_term, start_date:payload.start_date, end_date:payload.end_date, type:payload.type, trainer:payload.trainer});
            return response
        },

        async updateTrainingStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.toggle_training_status + payload.update, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTraining',{perPage:payload.perPage, currentPage:payload.page, search_term:payload.search_term, start_date:payload.start_date, end_date:payload.end_date, type:payload.type, trainer:payload.trainer});
            return response
        },

        async assignEmployee ({commit,dispatch}, payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.assign_employee_training, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('TRAINING_SUBMIT_SUCCESS', true);
                dispatch('fetchTraining',{perPage:payload.perPage, currentPage:payload.page, search_term:payload.search_term, start_date:payload.start_date, end_date:payload.end_date, type:payload.type, trainer:payload.trainer});
                return response

            }catch (e) {
                commit('TRAINING_ERROR', true);
            }finally {
                commit('TRAINING_LOADING', false);
            }
        },

        async deleteAssignedEmployee ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_assign_employee + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTraining',{perPage:payload.perPage, currentPage:payload.page, search_term:payload.search_term, start_date:payload.start_date, end_date:payload.end_date, type:payload.type, trainer:payload.trainer});
            return response
        },

        async fetchMyTraining ({ commit },payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_my_trainings+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count} = result.data
                commit('SET_MY_TRAINING_LIST', {results,pg_count});

            } catch(error) {
                commit('TRAINING_ERROR', true);
            }
            commit('TRAINING_LOADING', false);
        }
    },

    getters: {}
}
