<template>
    <div>
        <Breadcrumbs main="Initiative" title="Initiative Create" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">

                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>Initiative Title</label>
                                            <input class="form-control" type="text" placeholder="Enter initiative title *" v-model="project.title">
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Client <span><button class="btn-success ml-3">Add New</button></span></label>
                                            <select class="form-control digits" v-model="project.client">
                                                <option v-for="(client, index) in clients" :key="index">{{ client }}</option>
                                            </select>
                                        </div>
                                    </div> -->
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Priority</label>
                                            <select class="form-control digits" v-model="project.priority">
                                                <option v-for="(priority, index) in priorities" :key="index">{{ priority }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Status</label>
                                            <select class="form-control digits" v-model="project.status">
                                                <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Starting date</label>
                                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="project.start_date"></datepicker>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Deadline</label>
                                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="project.deadline"></datepicker>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Upload project file</label>
                                            <b-form-file
                                                :multiple="true"
                                                placeholder="Choose files  or drop them here..."
                                                drop-placeholder="Drop file here..."
                                                @change="uploadFile"
                                            ></b-form-file>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Project Details</label>
                                            <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter project details..." rows="4" v-model="project.details"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mb-0">
                                            <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                            <router-link to="/project/list">
                                                <button class="btn btn-danger" href="#">Go Back</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data(){
        return{
            project: {
                title: '',
                details: '',

                start_date: null,
                deadline: null,
                priority: 'HIGH',
                status: 'APPROVED',
                project_attachment: null
            },

            priorities: ['HIGH', 'MEDIUM', 'LOW'],
            statuses: ['PENDING', 'APPROVED', 'WORKING', 'COMPLETED'],
            clients: ['Catch Bangladesh', 'BitFlex', 'PixelTag', 'Catch Digital']
        };
    },

    methods: {
        uploadFile(e){
          this.project.project_attachment=e.target.files[0]
            // const image = e.target.files[0];
            // const reader = new FileReader();
            // reader.readAsDataURL(image);
            // reader.onload = e =>{
            //     // console.log(e)
            //     this.project.project_attachment = e.target.result;
            // };
        },

        onSubmit() {
            if(this.project.start_date !== null) {
                this.project.start_date = moment(String(this.project.start_date)).format("YYYY-MM-DD");
            }
            if(this.project.deadline !== null) {
                this.project.deadline = moment(String(this.project.deadline)).format("YYYY-MM-DD");
            }
            this.$store.dispatch("project/addProject", this.project).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.project.title = '',
                    this.project.details = '',
                    this.project.start_date = null,
                    this.project.deadline = null,
                    this.project.priority = 'HIGH',
                    this.project.status = 'APPROVED',
                    this.project.project_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
