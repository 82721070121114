<template>
<div>
    <Breadcrumbs main="HR" submain="Leave Management" title="Leave Requests" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="width: 100%">
                        <div style="max-width: fit-content;">
                            <router-link to="/hr/leave-management/leave-create">
                                <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                    <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                        <i class="fa fa-light fa-plus"></i>
                                        <h7>Add New Leave</h7>
                                    </div>
                                </button>
                            </router-link>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group class="datatable-btn" style="width: 340px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter =''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="statusSelected ||  start_date || end_date || selectedDepartment || selectedDesignation || selectedEmployee">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDesignation">
                                        <b-form-select v-model="selectedEmployee" :options="formattedEmployee"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(employee)="data">
                                        <span>
                                            <router-link :to="`/hr/leave-management/leave-history/${data.item.employee.id}`" class="link-style">
                                                {{ data.item.employee.first_name}} {{ data.item.employee.last_name}}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(leave_type)="data">
                                        <span>
                                            {{ data.item.leave_type.name}}
                                        </span>
                                    </template>

                                    <template #cell(leave_count)="data">
                                        <span v-if="data.item.total_days">
                                            {{ data.item.total_days}} Days
                                        </span>
                                    </template>

                                    <template #cell(subject)="data">
                                        <div @click="leaveDetails(data.item)">
                                            <p v-b-tooltip.hover title="Subject" v-b-modal.modal-prevent-closing-edit class="link-style">{{ data.item.subject}}</p>
                                        </div>
                                    </template>

                                    <template #cell(refer_to)="data">
                                        <span>
                                            <div v-if="data.item.refer_to !== null && data.item.refer_to.length > 0">
                                                <div class="customers">
                                                    <ul>
                                                        <ul v-for="(member, index) in data.item.refer_to.slice(0,5)" :key="index">
                                                            <li v-if="member.photo" class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" :src="host + member.photo" alt="" data-original-title=""></li>
                                                            <li v-else class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" src="../../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                        </ul>
                                                        <li class="d-inline-block mr-2" v-if="data.item.refer_to.length > 5">
                                                            <p class="f-12">+ {{ data.item.refer_to.length - 5 }}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p>N/A</p>
                                            </div>
                                        </span>
                                    </template>
                                    <template #cell(approved_by)="data">
                                        <div>
                                            <p style="max-width: 250px;">{{ data.item.approved_by ? data.item.approved_by.full_name : 'N/A' }}</p>
                                        </div>
                                    </template>

                                    <template #cell(status)="row">
                                        <span v-if="row.value == 'APPROVED'">
                                            <b-badge pill variant="success">{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'PENDING'">
                                            <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="primary" title="Update Status">{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'DUE'">
                                            <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="warning" title="Update Status">{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'NOT_ACCEPTED'">
                                            <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="danger" title="Update Status">NOT ACCEPTED</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <div>
                                                <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(data.item)" title="Reply"></feather>
                                            </div>
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRequest(data.item.id)" title="Delete"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Status Update Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Leave Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="formattedOptions"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Reply Create Modal -->
    <b-modal id="modal-scrollable" ref="modal" title="Leave Replies" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>
        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>

        <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleLeaveReplySubmit()">
                <button @click="handleLeaveReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>

    <!-- Leave Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refer?</div>
    </b-modal>

    <!-- Leave Request Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleRequestDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this request?</div>
    </b-modal>

    <!-- Leave Detail Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Leave Details" hide-footer>
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Employee Name" label-for="name-input">
                <b-form-input v-model="leave_details.employee_name" placeholder="" disabled></b-form-input>
            </b-form-group>

            <b-form-group label="Leave Type" label-for="name-input">
                <b-form-input v-model="leave_details.leave_type" placeholder="" disabled></b-form-input>
            </b-form-group>

            <b-form-group label="Subject" label-for="name-input">
                <b-form-input v-model="leave_details.subject" placeholder="" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Leave Days" label-for="name-input">
                <b-form-input v-model="leave_details.total_days" placeholder="" disabled></b-form-input>
            </b-form-group>

            <div class="row">
                <div class="col-sm-6">
                    <b-form-group label="Start Date" label-for="name-input">
                        <datepicker placeholder="Select start date" input-class="datepicker-here form-control digits" v-model="leave_details.start_date" disabled></datepicker>
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <b-form-group label="End Date" label-for="name-input">
                        <datepicker placeholder="Select end date" input-class="datepicker-here form-control digits" v-model="leave_details.end_date" disabled></datepicker>
                    </b-form-group>
                </div>
            </div>

            <b-form-group label="Reason" label-for="name-input">
                <b-form-textarea v-model="leave_details.reason" disabled ref="textarea" rows="2"></b-form-textarea>
            </b-form-group>

            <div class="d-flex flex-column" style="margin-top: 10px;">
                <div>
                    <h7>Reffered To</h7>
                </div>
                <div class="row mt-2">
                    <div class="text-center col-sm-6 w-full" v-for="user in leave_details.refer_to" :key="user.id">
                        <div class="w-100 mb-2 badge-style">
                            <h7>{{ user.first_name }}</h7>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import {
    myHost
} from '../../../../_helpers/host';
import moment from "moment";

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            start_date: null,
            end_date: null,
            statusSelected: '',
            filterShow: false,
            statuses: [{
                    id: 1,
                    name: 'Pending',
                    value: 'PENDING'
                },
                {
                    id: 2,
                    name: 'Approved',
                    value: 'APPROVED'
                },
                {
                    id: 3,
                    name: 'Due',
                    value: 'DUE',
                },
                {
                    id: 4,
                    name: 'Not Accepted',
                    value: 'NOT_ACCEPTED',
                }
            ],
            host: myHost.host,
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'employee',
                    label: 'Employee',
                    sortable: true
                },
                {
                    key: 'leave_type',
                    label: 'Leave Type',
                    sortable: true
                },
                {
                    key: 'subject',
                    label: 'Subject',
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: 'Start Date',
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: 'End Date',
                    sortable: true
                },
                {
                    key: 'leave_count',
                    label: 'Total Leave',
                    sortable: false
                },
                {
                    key: 'refer_to',
                    label: 'Referred To',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'approved_by',
                    label: 'Approved By',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            initialLoad: true,
            isModifyingDate: false,
            selectedStatus: '',
            update_id: '',
            filter: null,
            assignedMembers: [],
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',
            temp_request_id: '',
            leave_details: {
                id: '',
                employee_name: '',
                leave_type: '',
                subject: '',
                reason: '',
                start_date: '',
                end_date: '',
                refer_to: [],
                status: '',
                total_days: null
            },
            selectedDepartment: '',
            selectedDesignation: '',
            selectedEmployee: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState({
            items: state => state.leave.leaveList,
            count: state => state.leave.leaveList_count,
            user_list: state => state.employee.employee_list,
            ticket_reply: state => state.leave.ticket_reply,
            loading: state => state.leave.submitLoading,
            error: state => state.leave.submitError,
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation
        }),

        formattedOptions() {
            return [{
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },

        formattedEmployee() {
            return [{
                    value: '',
                    text: 'Select Employee',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [{
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [{
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        }
    },

    mounted() {
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("employee/fetchEmployeeList");
        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {

                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitial();
            } else {

                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitial();
            }
        }
    },

    watch: {
        selectedDepartment(newVal, oldVal) {
            this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
        },

        selectedDesignation(newVal, oldVal) {
            this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
        },

        filter(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("leave/fetchLeaveList", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        selectedEmployee(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("leave/fetchLeaveList", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    employee: this.selectedEmployee

                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        start_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("leave/fetchLeaveList", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter,
                            employee: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("leave/fetchLeaveList", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter,
                            employee: this.selectedEmployee

                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("leave/fetchLeaveList", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter,
                            employee: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("leave/fetchLeaveList", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter,
                            employee: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        statusSelected(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("leave/fetchLeaveList", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        leaveList(oldValue, newValue) {
            this.items = this.leaveList
        },

        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/hr/leave-management/leave-requests',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    },
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("leave/fetchLeaveList", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/hr/leave-management/leave-requests',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    },
                });
                this.perPage = newperPage
                this.$store.dispatch("leave/fetchLeaveList", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    employee: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        }
    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("leave/fetchLeaveList", {
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    employee: this.selectedEmployee
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        },
        clearFilter() {
            this.filterShow = false,
                this.filter = null,
                this.statusSelected = '',
                this.start_date = null,
                this.end_date = null,
                this.selectedDepartment = '',
                this.selectedDesignation = '',
                this.selectedEmployee = ''
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },

        leaveDetails(type) {
            this.leave_details.id = type.id
            this.leave_details.total_days = type.total_days
            this.leave_details.employee_name = type.employee.username
            this.leave_details.leave_type = type.leave_type.name
            this.leave_details.subject = type.subject
            this.leave_details.reason = type.reason
            this.leave_details.start_date = type.start_date
            this.leave_details.end_date = type.end_date
            this.leave_details.refer_to = type.refer_to
        },

        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.refer_to
            this.$store.dispatch("leave/fetchLeaveReply", this.my_reply.id)
        },

        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("leave/leaveAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                employee: this.selectedEmployee,
                from_hr: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        deletetAllocation(id) {
            this.temp_id = id
        },

        deleteRequest(id) {
            this.temp_request_id = id
        },

        handleAllocationDeleteOk() {
            this.$store.dispatch("leave/leaveDeleteRefer", {
                id: this.my_reply.id,
                refer_id: this.temp_id,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                employee: this.selectedEmployee,
                from_hr: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        handleRequestDeleteOk() {
            this.$store.dispatch("leave/leaveDeleteRequest", {
                id: this.temp_request_id,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                employee: this.selectedEmployee
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.temp_request_id = ''
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center')
            })
        },

        handleLeaveReplySubmit() {
            if (this.my_reply.reply) {
                this.$store.dispatch("leave/addLeaveReply", {
                    leave: this.my_reply.id,
                    reply: this.my_reply.reply
                }).then(response => {

                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.my_reply.id = '',
                            this.my_reply.reply = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-scrollable')
                })
            }
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        getStatus(status, id) {
            this.update_id = id
            this.selectedStatus = status
        },

        resetModal() {
            this.selectedStatus = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            if (this.selectedStatus === 'APPROVED') {
                this.$store.dispatch("leave/approveLeave", {
                    id: this.update_id,
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    employee: this.selectedEmployee

                }).then(response => {
                    if (response.code == 200) {

                    } else {

                    }
                })
            }
            this.$store.dispatch("leave/updateLeaveStatus", {
                id: this.update_id,
                status_for_change: this.selectedStatus,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                employee: this.selectedEmployee,
                from_hr: true
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.img-30 {
    width: 30px !important;
    height: 30px !important;
}
</style>
