import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate: 'LEAVE_UPDATE',
    smsDelete: 'LEAVE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {

        myleaveList: [],
        leaveList: [],
        leaveList_count:null,
        leaveTypeList: [],
        leave_detail:{},
        holidayList:{},
        //
        asset: [],
        asset_detail: {},
        get_my_asset: [],
        get_user_list: [],
        ticket_reply:[],
        leave_remaining:{},
        leave_remaining_of_user:{},

        leave_histories:[],
        count:null,

        asset_allocation_list: [],

        submitLoading: false,
        submitError: false,

        submitReplyLoading:false,
        submitReplyError:false,

       
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,
        SET_MY_LEAVE_LIST(state, {results,count}) {
            state.myleaveList = results;
            state.count = count;
        },
        SET_HOLIDAYS(state, holidays) {
            state.holidayList = holidays;
        },
        
        SET_LEAVE_LIST(state, {results,pg_count}) {
            state.leaveList = results;
            state.leaveList_count = pg_count;
        },
        SET_LEAVE_TYPE(state, leave) {
            state.leaveTypeList = leave;
        },
        SET_TICKET_REPLY(state,reply){
            state.ticket_reply = reply;
        },
        SET_LEAVE_REMAINING(state, leave) {
            state.leave_remaining = leave;
        },
        SET_LEAVE_REMAINING_USER(state, leave) {
            state.leave_remaining_of_user = leave;
        },
        SET_LEAVE_DETAIL(state, detail) {
            state.leave_detail = detail;
        },


        SET_LEAVE_HISTORY(state, history) {
            state.leave_histories = history;
        },



        SET_ASSET(state, asset) {
            state.asset = asset;
        },
        SET_MY_ASSET(state, asset) {
            state.get_my_asset = asset
        },
        SET_ASSET_ALLOCATION_LIST(state,asset){
            state.asset_allocation_list = asset
        },

        SET_ASSET_DETAIL (state, asset) {
            state.asset_detail = asset;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_REPLY_LOADING (state, loading) {
            state.submitReplyLoading = loading;
        },

        SUBMIT_REPLY_ERROR (state, hasError) {
            state.submitReplyError = hasError;
        },



        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },
        SET_USER_LIST (state, asset) {
            state.get_user_list = asset;
        },

    },

    actions: {

        async fetchMyLeaveList ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{

                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
                if (payload.search_term) params.append('search_term', payload.search_term);
               
                
                let url = V1API.get_my_leave_list + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results,count} = result.data

                commit('SET_MY_LEAVE_LIST', {results,count});
                return result

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
           
        },
        async fetchHolidayList ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_holidays, config);
                let response = result.data.data

                commit('SET_HOLIDAYS',response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
           
        },

        async fetchLeaveDetail ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.leave_detail + payload, config);
                let response = result.data.data

                commit('SET_LEAVE_DETAIL', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },



        async fetchLeaveRemaining ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_remaining_leave, config);
                let response = result.data.data

                commit('SET_LEAVE_REMAINING', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },

        async fetchLeaveRemainingUser ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.leave_remaining_user + payload, config);
                let response = result.data.data

                commit('SET_LEAVE_REMAINING_USER', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },



        


        async fetchLeaveList({commit},payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.employee) params.append('employee', payload.employee);
                
                
                let url = V1API.get_all_leave_list + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results,pg_count} = result.data

                commit('SET_LEAVE_LIST', {results,pg_count});
                return result

            } catch (error) {
                commit('SUBMIT_ERROR', true);
            }
            finally{
                commit('SUBMIT_LOADING', false);
            }
            
        },

        async leaveAddRefer ({commit, dispatch}, payload) {
            commit('SUBMIT_REPLY_LOADING', true);
            commit('SUBMIT_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_refer_to_leave + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
              
                if(payload.from_hr){
                    dispatch ('fetchLeaveList',
                        {currentPage:payload.page,
                        perPage:payload.perPage,
                        start_date:payload.start_date,
                        end_date:payload.end_date,
                        status:payload.status,
                        search_term:payload.search_term,
                        employee:payload.employee
                    })
                }
                else if(payload.from_details){
                    dispatch ('fetchLeaveDetail',payload.id)
                }


                dispatch ('fetchMyLeaveList',{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status,search_term:payload.search_term})

              
                return response

            }catch (e) {
                commit('SUBMIT_REPLY_ERROR', true);
            }finally {
                commit('SUBMIT_REPLY_LOADING', false);
            }
        },

        async leaveDeleteRefer ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.delete_refer_to_leave + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
                if(payload.from_hr){
                    dispatch ('fetchLeaveList',
                        {currentPage:payload.page,
                        perPage:payload.perPage,
                        start_date:payload.start_date,
                        end_date:payload.end_date,
                        status:payload.status,
                        search_term:payload.search_term,
                        employee:payload.employee
                    })
                }
                else if(payload.from_details){
                    dispatch ('fetchLeaveDetail',payload.id)
                }
                dispatch ('fetchMyLeaveList',{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status,search_term:payload.search_term})
              
            
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async leaveDeleteRequest ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.delete(V1API.delete_leave_request + payload.id, config).then(result=>{
                    let data = result.data
                    return data

                });

                dispatch ('fetchLeaveList',
                    {currentPage:payload.page,
                    perPage:payload.perPage,
                    start_date:payload.start_date,
                    end_date:payload.end_date,
                    status:payload.status,
                    search_term:payload.search_term,
                    employee:payload.employee
                })
            
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        
        async addLeaveReply ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_leave_reply, {

                    leave: payload.leave,
                    reply:payload.reply,
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch ('fetchLeaveList',{currentPage:payload.page,perPage:payload.perPage})
                // dispatch ('fetchMyLeaveList',{currentPage:payload.page,perPage:payload.perPage})
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },





        async fetchLeaveType({commit},payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{


                let params = new URLSearchParams();
        
                if (payload.active_list) params.append('active', payload.active_list);
               
                
                let url = V1API.get_leave_type_list + '?' + params.toString();
                let result = await axios.get(url, config);

                let response = result.data.data

                commit('SET_LEAVE_TYPE', response);
                return result

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally{
                commit('SUBMIT_LOADING', false);
            }
           
        },
        
        async updateLeaveStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.update_leave_status + "/" + payload.id + "/" + payload.status_for_change, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch("fetchLeaveDetail",payload.id)
                if(payload.from_hr){
                    dispatch ('fetchLeaveList',
                        {currentPage:payload.page,
                        perPage:payload.perPage,
                        start_date:payload.start_date,
                        end_date:payload.end_date,
                        status:payload.status,
                        search_term:payload.search_term,
                        employee:payload.employee
                    })
                }
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async approveLeave({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let response = await axios.post(V1API.leave_approve + payload.id, {}, config).then(result => {
                    let data = result.data
                    return data
                })

                dispatch('fetchLeaveList', {
                    currentPage: payload.page,
                    perPage: payload.perPage,
                    start_date: payload.start_date,
                    end_date: payload.end_date,
                    status: payload.status,
                    search_term: payload.search_term,
                    employee: payload.employee
                })

                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchLeaveReply ({ commit }, payload) {
            commit('SUBMIT_REPLY_LOADING', true);
            commit('SUBMIT_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_replies_of_leave + payload, config);
                let response = result.data
                commit('SET_TICKET_REPLY', response.data);

            } catch(error) {
                commit('SUBMIT_REPLY_ERROR', true);
            }
            finally {

                commit('SUBMIT_REPLY_LOADING', false);
            }
           
        },



        async addLeaveType ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },

            };
            try{

                let response = await axios.post(V1API.create_leave_type, payload, config).then(result=>{

                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchLeaveType',{});
                return response

            }catch (e) {

                commit('SUBMIT_ERROR', true);
            }finally {

                commit('SUBMIT_LOADING', false);
            }
        },



        async deleteLeaveType ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_leave_type + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchLeaveType',{});

            return response
        },


        async updateLeaveType ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,

                async () => {
                    response = await axios.patch(V1API.update_leave_type + payload.id, {
                        name: payload.name,
                        days: payload.days,
                        active: payload.active
                    }, config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchLeaveType',{});

                }
            )
            return response
        },


        async leaveApply({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },

            };
            try{

                let response = await axios.post(V1API.apply_leave, payload, config).then(result=>{

                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch ('fetchMyLeaveList')
                return response

            }catch (e) {

                commit('SUBMIT_ERROR', true);
            }finally {

                commit('SUBMIT_LOADING', false);
            }
        },

        async leaveApplyByAdmin({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },

            };
            try{

                let response = await axios.post(V1API.leave_create_by_admin, payload, config).then(result=>{

                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch ('fetchMyLeaveList')
                return response

            }catch (e) {

                commit('SUBMIT_ERROR', true);
            }finally {

                commit('SUBMIT_LOADING', false);
            }
        },


        // Leave History
        async fetchLeaveHistory ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_leave_history+payload, config);
                let response = result.data.data

                commit('SET_LEAVE_HISTORY', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
           
        },
        async deleteLeaveHistory ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.leave_history_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchLeaveHistory',payload.profile);

            return response
        },



    },

    getters: {}
}
