<template>
<div>
    <!-- page-wrapper Start-->
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class="login-card">
                    <div>
                        <div>
                            <a class="logo">
                                <img class="img-fluid for-light" src="../assets/images/logo/login.png" alt="looginpage" style="max-width: 17%" />
                                <img class="img-fluid for-dark" src="../assets/images/logo/logo_dark.png" alt="looginpage" style="max-width: 17%" />
                            </a>
                        </div>
                        <div class="login-main" v-show="giveEmail">
                            <b-card>
                                <b-card-text>
                                    <div class="alert alert-secondary">
                                        <p>Enter the email address associated with your account and we'll send you a link to reset your password</p>
                                    </div>
                                    <form class="theme-form" @submit.prevent="handleSubmit">
                                        <div class="form-group">
                                            <label for="email">Email</label>
                                            <input type="text" v-model="email" name="email" class="form-control" :class="{ 'is-invalid':submitted &&  !email }" />
                                            <div v-show="submitted && !email" class="invalid-feedback">
                                                Email is required
                                            </div>
                                        </div>

                                        <div class="form-group mt-3 mb-0">
                                            <button class="btn btn-primary btn-block" @click="checkEmail()">
                                                Continue
                                            </button>
                                        </div>

                                    </form>
                                </b-card-text>
                            </b-card>
                        </div>

                        <div class="login-main" v-show="giveOTP">
                            <b-card>
                                <div class="alert alert-secondary">
                                    <p>{{ this.generated_otp }}</p>
                                </div>
                                <b-card-text>
                                    <form class="theme-form" @submit.prevent="handleSubmit">
                                        <div class="form-group">
                                            <label for="otp">OTP</label>

                                            <input type="text" name="otp" v-model="otp" class="form-control" :class="{ 'is-invalid': submitted && !otp }" />
                                            <div v-show="!otp" class="invalid-feedback">
                                                OTP is required
                                            </div>
                                        </div>

                                        <div class="form-group mt-3 mb-0">
                                            <button class="btn btn-primary btn-block" @click="checkOTP()">
                                                Continue
                                            </button>
                                        </div>

                                    </form>
                                </b-card-text>
                            </b-card>
                        </div>

                        <div class="login-main" v-show="givePassword">
                            <b-card>
                                <b-card-text>
                                    <form class="theme-form" @submit.prevent="handleSubmit">
                                        <div class="form-group">
                                            <label for="email">Email</label>

                                            <input type="text" name="email" v-model="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" disabled/>
                                            <div v-show="submitted && !email" class="invalid-feedback">
                                                Email is required
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="email">New Password</label>
                                            <input type="text" v-model="new_password" name="email" class="form-control" :class="{ 'is-invalid': submitted && !new_password }" />
                                            <div v-show="submitted && !new_password" class="invalid-feedback">
                                                Password is required
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="email">Retype New Password</label>
                                            <input type="text" v-model="new_password2" name="email" class="form-control" :class="{ 'is-invalid': submitted && !new_password2 }" />
                                            <div v-show="submitted && !new_password2" class="invalid-feedback">
                                                Retype your password
                                            </div>
                                        </div>

                                        <div class="form-group mt-3 mb-0">
                                            <button class="btn btn-primary btn-block" @click="submitPassword()">
                                                Continue
                                            </button>
                                        </div>

                                    </form>
                                </b-card-text>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- latest jquery-->
</div>
</template>

<script>

import Userauth from '../auth/js/index';
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    name: 'login',

    data() {
        return {

            email: null,
            otp: '',
            new_password: '',
            new_password2: '',

            generated_otp: '',
            user: '',

            submitted: false,
            giveEmail: true,
            giveOTP: false,
            givePassword: false,

        };
    },

    computed: {

    },

    created() {

    },

    methods: {
        checkEmail() {
            this.$store.dispatch("resetPassword/emailCheck", this.email).then(response => {
                if (response.status == 200) {

                    this.generated_otp = response.otp
                    this.user = response.user
                    this.email = response.email
                    // Swal.fire({
                    //     title: "Success!",
                    //     text: response.response,
                    //     icon: "success"
                    // });
                    this.giveOTP = true
                    this.givePassword = false
                    this.giveEmail = false
                    this.submitted = true;

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

        },
        checkOTP() {
            this.$store.dispatch("resetPassword/verifyOTP", {
                otp: this.otp,
                user: this.user
            }).then(response => {
                if (response.code == 200) {

                    this.givePassword = true
                    this.giveOTP = false
                    this.giveEmail = false
                    this.submitted = true

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
        submitPassword() {
            if (this.new_password !== this.new_password2) {
                Swal.fire({
                    title: "Error!",
                    text: "Password doesn't match",
                    icon: "error"
                });

            } else {
                this.$store.dispatch("resetPassword/resetPassword", {
                    email: this.email,
                    new_password: this.new_password,
                    new_password2: this.new_password2
                }).then(response => {
                    if (response.code == 200) {

                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.givePassword = true
                        this.giveOTP = false
                        this.giveEmail = false
                        this.submitted = true

                        setTimeout(() => {
                            this.$router.push('/auth/login')
                        }, 2000)

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }

        },

        // show/hide password
        showPassword: function () {
            if (this.type === 'password') {
                this.type = 'text';
            } else {
                this.type = 'password';
            }
        }
    }
};
</script>
