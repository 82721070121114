<template>
<div>
    <Breadcrumbs main="BNPS" submain="Edit" title="Draft Edit" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">

                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div style="display: flex; flex-direction: column;">
                                        <div class="row">
                                            <div class="col-sm-12 d-flex justify-content-end">
                                                <span v-if="draft_status == 'pending'">
                                                    <b-badge pill variant="warning" style="text-transform: uppercase;">{{draft_status}}</b-badge>
                                                </span>
                                                <span v-if="draft_status == 'approved'">
                                                    <b-badge pill variant="success" style="text-transform: uppercase;">{{draft_status }}</b-badge>
                                                </span>
                                                <span v-if="draft_status == 'recheck'">
                                                    <b-badge pill variant="primary" style="text-transform: uppercase;">{{ draft_status }}</b-badge>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <h6>Reported To</h6>
                                                    <multiselect v-model="reported" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.topics">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Topics</h6>
                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Enter topics" v-model="form_data.topics">
                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.program_date">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Program Date</h6>

                                                    </div>
                                                    <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="form_data.program_date"></datepicker>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.event_expenditure">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Event Expenditure</h6>

                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Enter event expenditure" v-model="form_data.event_expenditure">
                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.venue">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Venue</h6>

                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Enter venue" v-model="form_data.venue">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.activity_code">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Activity Code</h6>

                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Enter activity code" v-model="form_data.activity_code">
                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.budget_code">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Budget Code</h6>

                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Enter budget code" v-model="form_data.budget_code">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="row">

                                            </div> -->
                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.participants">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Participants</h6>

                                                    </div>
                                                    <div>
                                                        <!-- <div class="" style="display: flex; gap: 16px;">
                                                                <div style="width: 40%;">
                                                                    <h6>Category</h6>
                                                                </div>

                                                                <div style="width: 40%;">
                                                                    <h6>Total</h6>
                                                                </div>

                                                                <div style="width: 20%;">
                                                                    <h6>Action</h6>
                                                                </div>
                                                            </div> -->

                                                        <div style="display: flex; gap: 16px; margin-bottom: 12px;" v-for="(fo, index) in form_data.participants" :key="index">
                                                            <div style="width: 40%;">
                                                                <div class="w-fullbg-white rounded-sm">
                                                                    <b-form-input v-model="fo.category" type="text" placeholder="Enter category"></b-form-input>
                                                                </div>
                                                            </div>

                                                            <div style="width: 40%;">
                                                                <div class="w-fullbg-white rounded-sm">
                                                                    <b-form-input v-model="fo.total" type="number" placeholder="Enter total"></b-form-input>
                                                                </div>
                                                            </div>

                                                            <div style="width: 20%; display: flex; align-items: center;">

                                                                <svg @click="addField(input, form_data.participants)" width="24" height="24" class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                                    <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                                </svg>

                                                                <svg v-show="form_data.participants.length > 1" @click="removeField(index, form_data.participants)" width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                                    <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.professions">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Professions</h6>

                                                    </div>
                                                    <div>
                                                        <!-- <div class="" style="display: flex; gap: 16px;">
                                                                <div style="width: 45%;">
                                                                    <h6>Profession Name</h6>
                                                                </div>

                                                                <div style="width: 20%;">
                                                                    <h6>Action</h6>
                                                                </div>
                                                            </div> -->

                                                        <div style="display: flex; gap: 16px; margin-bottom: 12px;" v-for="(fo, index) in form_data.professions" :key="index">
                                                            <div style="width: 45%;">
                                                                <div class="w-fullbg-white rounded-sm">
                                                                    <b-form-input v-model="fo.name" type="text" placeholder="Enter profession name"></b-form-input>
                                                                </div>
                                                            </div>

                                                            <div style="width: 20%; display: flex; align-items: center;">

                                                                <svg @click="addProfessionsField(input, form_data.professions)" width="24" height="24" class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                                    <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                                </svg>

                                                                <svg v-show="form_data.professions.length > 1" @click="removeProfessionsField(index, form_data.professions)" width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                                    <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.monthly_income">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Monthly Income</h6>

                                                    </div>

                                                    <multiselect v-model="form_data.monthly_income" tag-placeholder="Monthly Income" placeholder="Add monthly income" label="name" :max="monthly_income_list.length" track-by="id" :options="monthly_income_list" :multiple="true" :taggable="true"></multiselect>

                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.age_group">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Age Group</h6>

                                                    </div>
                                                    <multiselect v-model="form_data.age_group" tag-placeholder="Age Group" placeholder="Add age group" label="name" :max="age_group_list.length" track-by="id" :options="age_group_list" :multiple="true" :taggable="true"></multiselect>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.program_objectives">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Program Objective</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.program_objectives" rows="5" max-rows="10" placeholder="Enter program objective"></b-form-textarea>

                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.activity_in_brief">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Activity in brief</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.activity_in_brief" rows="5" max-rows="10" placeholder="Enter activity (Upto 200 words)"></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.fac_of_the_program">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Facilitator of the Program</h6>

                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Facilitator of the Program" v-model="form_data.fac_of_the_program">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.outcome">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Outcome</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.outcome" rows="5" max-rows="10" placeholder="Enter outcomes (Upto 100 words) maximum five bullet point"></b-form-textarea>

                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.challenges">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Challenges</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.challenges" rows="5" max-rows="10" placeholder="Enter challenges (Upto 100 words) maximum five bullet point"></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.learnings">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Learnings</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.learnings" rows="5" max-rows="10" placeholder="Enter learnings (Upto 100 words) maximum five bullet point"></b-form-textarea>

                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.recommendations">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Recommendations</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.recommendations" rows="5" max-rows="10" placeholder="Enter recommendations (Upto 100 words) maximum five bullet point"></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.risk_factor">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Risk factors</h6>
                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Enter risk factors" v-model="form_data.risk_factor">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.remarks">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Remarks</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.remarks" rows="3" max-rows="8" placeholder="Enter remarks (If any)"></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.report_attach">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Report Attachment</h6>

                                                    </div>
                                                    <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." multiple :file-name-formatter="formatNames" @change="uploadAttachFile"></b-form-file>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row mt-2">
                                        <div class="col">
                                            <div class="form-group mb-0">
                                                <button @click="handleDraftSubmit" class="btn btn-info mr-3">Save as Draft</button>
                                                <button @click="onSubmit" class="btn btn-success mr-3" v-if="draft_status === 'approved'">Submit</button>
                                                <!-- <router-link :to="`/internal-activity/project-details/${details.project}`">
                                                        <button class="btn btn-danger" href="#">Go Back</button>
                                                    </router-link> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import Multiselect from 'vue-multiselect';
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect
    },

    data() {
        return {

            form: {
                venue: true,
                activity_code: true,
                budget_code: true,
                program_date: true,
                participants: true,
                professions: true,
                monthly_income: true,
                age_group: true,
                topics: true,
                event_expenditure: true,
                program_objectives: true,
                activity_in_brief: true,
                fac_of_the_program: true,
                outcome: true,
                challenges: true,
                learnings: true,
                recommendations: true,
                risk_factor: true,
                remarks: true,
                report_attach: true,

            },
            form_data: {
                activity: null,
                venue: null,
                activity_code: null,
                budget_code: null,
                program_date: null,
                participants: [{
                    category: null,
                    total: null
                }],
                professions: [{
                    name: null,
                }],
                monthly_income: [],
                age_group: [],
                topics: null,
                event_expenditure: null,
                program_objectives: null,
                activity_in_brief: null,
                fac_of_the_program: null,
                outcome: null,
                challenges: null,
                learnings: null,
                recommendations: null,
                risk_factor: null,
                remarks: null,

            },
            reported: [],
            reported_to: [],

            report_attach: null,
            priorities: [{
                    name: 'LOW',
                    value: 1,
                },
                {
                    name: 'MEDIUM',
                    value: 2,
                },
                {
                    name: 'HIGH',
                    value: 3,
                }
            ],

            taskStatus: [{
                    name: 'Pending',
                    value: 'PENDING',
                },
                {
                    name: 'In Progress',
                    value: 'IN-PROGRESS',
                },
                {
                    name: 'Hold',
                    value: 'HOLD',
                },
                {
                    name: 'Completed',
                    value: 'COMPLETED',
                }
            ],

            activity_locations: [{
                location_id: null,
                quantity: null,
            }],
            draft_id: null,
            draft_status: null,
            monthly_income_list: [{
                    id: '1',
                    name: '3000-5000',
                },
                {
                    id: '2',
                    name: '5001-7000',
                },
                {
                    id: '3',
                    name: '7001-9000',
                },
                {
                    id: '4',
                    name: '9001-11000',
                },
                {
                    id: '5',
                    name: '11001-13000',
                },
                {
                    id: '6',
                    name: '13001-15000',
                },
                {
                    id: '7',
                    name: '15000>',
                },

            ],

            age_group_list: [{
                    id: '1',
                    name: '<10',
                },
                {
                    id: '2',
                    name: '10-14',
                },
                {
                    id: '3',
                    name: '15-18',
                },
                {
                    id: '4',
                    name: '19-24',
                },
                {
                    id: '5',
                    name: '25-29',
                },
                {
                    id: '6',
                    name: '<35-50',
                },
                {
                    id: '7',
                    name: '51>',
                },

            ],

        }
    },

    computed: {
        ...mapState({

            draft_details: state => state.bnps_project.draft_details,
            form_edit: state => state.bnps_project.form,
            user_list: state => state.bnps_project.location_employee,

        }),

    },

    mounted() {
        this.$store.dispatch("bnps_project/fetchEmployeeListByLocation", this.$route.query.location);

        this.$store.dispatch("bnps_project/fetchForm", this.$route.query.form)
        this.$store.dispatch("bnps_project/fetchDraftDetails", this.$route.query.draft)

    },

    watch: {

        form_edit(newValue, oldValue) {

            this.form = this.form_edit

        },
        draft_details(newValue, oldValue) {

            this.draft_status = this.draft_details.status
            this.draft_id = this.draft_details.id
            this.form_data.activity = this.draft_details.activity
            this.form_data.venue = this.draft_details.venue
            this.form_data.activity_code = this.draft_details.activity_code
            this.form_data.budget_code = this.draft_details.budget_code
            this.form_data.program_date = this.draft_details.program_date
            this.form_data.participants = this.draft_details.participants == null ? [{
                category: null,
                total: null
            }] : JSON.parse(this.draft_details.participants)

            this.form_data.professions = this.draft_details.professions == null ? [{
                name: null,
            }] : JSON.parse(this.draft_details.professions)

            this.form_data.monthly_income = this.draft_details.monthly_income == null ? [] : JSON.parse(this.draft_details.monthly_income)
            this.form_data.age_group = this.draft_details.age_group == null ? [] : JSON.parse(this.draft_details.age_group)
            this.form_data.topics = this.draft_details.topics
            this.form_data.event_expenditure = this.draft_details.event_expenditure
            this.form_data.program_objectives = this.draft_details.program_objectives
            this.form_data.activity_in_brief = this.draft_details.activity_in_brief
            this.form_data.fac_of_the_program = this.draft_details.fac_of_the_program
            this.form_data.outcome = this.draft_details.outcome
            this.form_data.challenges = this.draft_details.challenges
            this.form_data.learnings = this.draft_details.learnings
            this.form_data.recommendations = this.draft_details.recommendations
            this.form_data.risk_factor = this.draft_details.risk_factor
            this.form_data.remarks = this.draft_details.remarks
            this.form_data.report_attach = this.draft_details.report_attach
            this.reported = this.draft_details.reported_to
        }
    },

    methods: {
        formatNames(files) {
            return files.length === 1 ? files[0].name : `${files.length} files selected`
        },
        uploadAttachFile(e) {
            // alert(JSON.stringify(e.target.files))
            // console.log(e.target.files)
            this.report_attach = e.target.files

        },
        toggleProperty(property) {
            if (this.form.hasOwnProperty(property)) {
                this.form[property] = !this.form[property];
            }
        },

        addField(value, fieldType) {
            fieldType.push({
                category: null,
                total: null
            });
        },

        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        addProfessionsField(value, fieldType) {
            fieldType.push({
                name: null,
            });
        },

        removeProfessionsField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        handleDraftSubmit() {

            let form_id = this.form_edit.id

            if (this.form_data.program_date !== null) {
                this.form_data.program_date = moment(String(this.form_data.program_date)).format("YYYY-MM-DD");
            }
            this.reported.forEach(item => {
                this.reported_to.push(item.id);
            })

            this.form_data.activity = form_id
            this.$store.dispatch("bnps_project/draftActivityForm", {
                form_data: this.form_data,
                attach: this.report_attach,
                reported_to: this.reported_to,
                // activity: this.$route.query.activity,
                // formid:this.$route.params.id

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.form_data.activity = null
                    this.form_data.venue = null
                    this.form_data.activity_code = null
                    this.form_data.budget_code = null
                    this.form_data.program_date = null
                    this.form_data.participants = null
                    this.form_data.professions = null
                    this.form_data.monthly_income = null
                    this.form_data.age_group = null
                    this.form_data.topics = null
                    this.form_data.event_expenditure = null
                    this.form_data.program_objectives = null
                    this.form_data.activity_in_brief = null
                    this.form_data.fac_of_the_program = null
                    this.form_data.outcome = null
                    this.form_data.challenges = null
                    this.form_data.learnings = null
                    this.form_data.recommendations = null
                    this.form_data.risk_factor = null
                    this.form_data.remarks = null

                    setTimeout(() => this.$router.push('/internal-activity/pending-drafts'), 2000)

                } else if (response.code == 400) {
                    this.$store.dispatch("bnps_project/editDraftActivity", {
                        draft_id: this.draft_id,
                        form_data: this.form_data,
                        attach: this.report_attach,
                        reported_to: this.reported_to,
                    }).then(response => {
                        if (response.code == 200) {

                            this.form_data.activity = null
                            this.form_data.venue = null
                            this.form_data.activity_code = null
                            this.form_data.budget_code = null
                            this.form_data.program_date = null
                            this.form_data.participants = null
                            this.form_data.professions = null
                            this.form_data.monthly_income = null
                            this.form_data.age_group = null
                            this.form_data.topics = null
                            this.form_data.event_expenditure = null
                            this.form_data.program_objectives = null
                            this.form_data.activity_in_brief = null
                            this.form_data.fac_of_the_program = null
                            this.form_data.outcome = null
                            this.form_data.challenges = null
                            this.form_data.learnings = null
                            this.form_data.recommendations = null
                            this.form_data.risk_factor = null
                            this.form_data.remarks = null

                            Swal.fire({
                                title: "Success!",
                                text: response.response,
                                icon: "success"
                            });
                            if(this.$route.query.status === 'pending'){
                                setTimeout(() => this.$router.push('/internal-activity/pending-drafts'), 2000)
                            }
                            if(this.$route.query.status === 'approved'){
                                setTimeout(() => this.$router.push('/internal-activity/approved-drafts'), 2000)
                            }
                           
                           

                        } else {
                            Swal.fire({
                                title: "Error!",
                                text: response.response,
                                icon: "error"
                            });
                        }
                    })
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        onSubmit() {
            let form_id = this.form_edit.id

            // let project_id = this.details.project
            if (this.form_data.program_date !== null) {
                this.form_data.program_date = moment(String(this.form_data.program_date)).format("YYYY-MM-DD");
            }
            this.form_data.activity = form_id
            this.$store.dispatch("bnps_project/submitActivityForm", {
                form_data: this.form_data,
                attach: this.report_attach,

            }).then(response => {
                if (response.code == 200) {

                    if (this.draft_id !== null) {
                        this.$store.dispatch("bnps_project/deleteDraftActivity", {
                            delete: this.draft_id,
                        }).then(response => {
                            if (response.code == 200) {

                            } else {

                            }

                        })
                    }

                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.form_data.activity = null
                    this.form_data.venue = null
                    this.form_data.activity_code = null
                    this.form_data.budget_code = null
                    this.form_data.program_date = null
                    this.form_data.participants = null
                    this.form_data.professions = null
                    this.form_data.monthly_income = null
                    this.form_data.age_group = null
                    this.form_data.topics = null
                    this.form_data.event_expenditure = null
                    this.form_data.program_objectives = null
                    this.form_data.activity_in_brief = null
                    this.form_data.fac_of_the_program = null
                    this.form_data.outcome = null
                    this.form_data.challenges = null
                    this.form_data.learnings = null
                    this.form_data.recommendations = null
                    this.form_data.risk_factor = null
                    this.form_data.remarks = null

                    if(this.$route.query.status === 'approved'){
                                setTimeout(() => this.$router.push('/internal-activity/approved-drafts'), 2000)
                    }

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }

    }
};
</script>

<style scoped>
.low-opacity {
    opacity: 0.5;
}
</style>
