<template>
<div>
    <Breadcrumbs main="HR" title="Assignments" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group style="width: 340px;">
                                    <b-form-input v-model="search_term" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="search_term = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="statusSelected || start_date || end_date || priority || selectedEmployee">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="priority" :options="formattedPriorities"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDesignation">
                                        <b-form-select v-model="selectedEmployee" :options="formattedEmployee"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(raised_by)="data">
                                        {{ data.item.raised_by.first_name }} {{ data.item.raised_by.last_name }}
                                    </template>

                                    <template #cell(ticket_id)="data">
                                        <div @click="TicketDetails(data.item)" class="link-style">
                                            <h7 v-b-tooltip.hover title="Details" v-b-modal.modal-prevent-closing-detail>{{data.item.ticket_id}}</h7>
                                        </div>
                                    </template>

                                    <template #cell(status)="row">
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'CLOSED'">
                                            <b-badge v-b-modal.modal-prevent-closing-status pill variant="warning" v-b-tooltip.hover title="Update Status">{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'OPEN'">
                                            <b-badge v-b-modal.modal-prevent-closing-status pill variant="success" v-b-tooltip.hover title="Update Status">{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(priority)="row">
                                        <span @click="getPriority(row.value, row.item.id)" v-if="row.value == 'LOW'">
                                            <b-badge v-b-modal.modal-prevent-closing-priority pill variant="info" v-b-tooltip.hover title="Update Priority">{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getPriority(row.value, row.item.id)" v-if="row.value == 'MEDIUM'">
                                            <b-badge v-b-modal.modal-prevent-closing-priority pill variant="warning" v-b-tooltip.hover title="Update Priority">{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getPriority(row.value, row.item.id)" v-if="row.value == 'HIGH'">
                                            <b-badge v-b-modal.modal-prevent-closing-priority pill variant="danger" v-b-tooltip.hover title="Update Priority">{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(refer_to)="data">
                                        <span>
                                            <div v-if="data.item.refer_to !== null && data.item.refer_to.length > 0">
                                                <div class="customers">
                                                    <ul>
                                                        <ul v-for="(member, index) in data.item.refer_to.slice(0,5)" :key="index">
                                                            <li v-if="member.photo" class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" :src="host + member.photo" alt="" data-original-title=""></li>
                                                            <li v-else class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" src="../../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                        </ul>
                                                        <li class="d-inline-block mr-2" v-if="data.item.refer_to.length > 5">
                                                            <p class="f-12">+ {{ data.item.refer_to.length - 5 }}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div v-else v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(data.item)" title="Refer">
                                                <p>N/A</p>
                                            </div>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(data.item)" title="Reply"></feather>
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteTicket(data.item.id)" title="Delete"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Ticket Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Assignment" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Refer To" label-for="name-input">
                <div style="display: flex; flex-direction: column; gap: 15px;">
                    <multiselect v-model="assignedMembers" tag-placeholder="Refer others" placeholder="Search or add a employee" label="user_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                </div>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Ticket Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this ticket?</div>
    </b-modal>

    <!-- Update Status -->
    <b-modal id="modal-prevent-closing-status" ref="modal" title="Update Assignment Status" @show="resetModal" @hidden="resetModal" @ok="handleStatusOk">
        <form ref="form" @submit.stop.prevent="handleStatusSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Update Priority -->
    <b-modal id="modal-prevent-closing-priority" ref="modal" title="Update Assignment Priority" @show="resetModal" @hidden="resetModal" @ok="handlePriorityOk">
        <form ref="form" @submit.stop.prevent="handlePrioritySubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedPriority" :options="priorities"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Reply Modal -->
    <b-modal id="modal-scrollable" ref="modal" title="Assignment Reply" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name + ' ' + user.last_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>

        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="user_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
        <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleReplySubmit()">
                <button @click="handleReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>

    <!-- Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refere?</div>
    </b-modal>

    <!-- Ticket Detail Modal -->
    <b-modal id="modal-prevent-closing-detail" ref="modal" title="Assignment Details" hide-footer>
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <div class="row">
                <div class="col">
                    <b-form-group label="Raised By" label-for="name-input">
                        <b-form-input v-model="fullName" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <b-form-group label="Reason" label-for="name-input">
                <b-form-input v-model="ticket_details.subject" placeholder="Enter holiday title" disabled></b-form-input>
            </b-form-group>

            <div class="row">
                <div class="col-sm-4">
                    <b-form-group label="Created Date" label-for="name-input">
                        <datepicker placeholder="Select start date" input-class="datepicker-here form-control digits" v-model="ticket_details.created_date" disabled></datepicker>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group label="Status" label-for="name-input">
                        <b-form-input v-model="ticket_details.status" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group label="Priority" label-for="name-input">
                        <b-form-input v-model="ticket_details.priority" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <b-form-group label="Details" label-for="name-input">
                <b-form-textarea id="textarea-rows" placeholder="" rows="4" v-model="ticket_details.details" disabled></b-form-textarea>
            </b-form-group>

            <div class="text-center" style="margin-top: 10px; margin-bottom: 6px;">
                <h6>Reffered To</h6>
            </div>
            <div class="row">
                <div class="text-center col-sm-6 w-full" v-for="user in ticket_details.refer_to" :key="user.id">
                    <div class="w-100 mb-2 badge-style">
                        <h7>{{ user.first_name + ' ' + user.last_name }}</h7>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    mapState
} from 'vuex';
import {
    myHost
} from '../../../../_helpers/host';
import moment from 'moment';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            filterShow: false,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'ticket_id',
                    label: 'Assignment ID',
                    sortable: true
                },
                {
                    key: 'raised_by',
                    label: 'Raised By',
                    sortable: true
                },
                {
                    key: 'created_date',
                    label: 'Assignment Date',
                    sortable: true
                },
                {
                    key: 'subject',
                    label: 'Subject',
                    sortable: true
                },
                {
                    key: 'refer_to',
                    label: 'Reffered To',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'priority',
                    label: 'Priority',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            ticket_id: null,
            update_ticket_id: '',
            selectedStatus: '',
            update_priority_id: '',
            selectedPriority: '',
            edit_ticket: {
                id: '',
                subject: '',
                refer_to: null,
            },
            ticket_details: {
                id: '',
                created_date: '',
                subject: '',
                details: '',
                status: '',
                priority: '',
                refer_to: '',
                raised_by: '',
            },
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            initialLoad: true,
            isModifyingDate: false,
            assignedMembers: [],
            statuses: ['OPEN', 'CLOSED'],
            priorities: ['HIGH', 'MEDIUM', 'LOW'],
            temp_id: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            search_term: null,
            start_date: null,
            end_date: null,
            statusSelected: '',
            selectedDepartment: '',
            selectedDesignation: '',
            selectedEmployee: '',
            priority: '',
            statuses_new: [{
                    id: 1,
                    name: 'Open',
                    value: 'OPEN'
                },
                {
                    id: 2,
                    name: 'Closed',
                    value: 'CLOSED'
                }
            ],
            priorities_new: [{
                    id: 1,
                    name: 'High',
                    value: 'HIGH'
                },
                {
                    id: 2,
                    name: 'Medium',
                    value: 'MEDIUM'
                },
                {
                    id: 2,
                    name: 'Low',
                    value: 'LOW'
                }
            ]
        };
    },

    watch: {
        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/hr/ticket/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("ticket/fetchTicket", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    search_term: this.search_term,
                    priority: this.priority,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/hr/ticket/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("ticket/fetchTicket", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    search_term: this.search_term,
                    priority: this.priority,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    raised_by: this.selectedEmployee,
                    
                    
                }).then(() => {
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }
        },

        selectedDepartment(newVal, oldVal) {
            this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
        },

        selectedDesignation(newVal, oldVal) {
            this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
        },

        priority(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("ticket/fetchTicket", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.search_term,
                    priority: this.priority,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        search_term(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("ticket/fetchTicket", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.search_term,
                    priority: this.priority,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        statusSelected(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("ticket/fetchTicket", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.search_term,
                    priority: this.priority,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        selectedEmployee(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("ticket/fetchTicket", {
                    perPage: this.perPage,
                    currentPage: 1,
                    search_term: this.search_term,
                    priority: this.priority,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                    this.initialLoad = false;
                });
            }

        },

        start_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("ticket/fetchTicket", {
                            perPage: this.perPage,
                            currentPage: 1,
                            search_term: this.search_term,
                            priority: this.priority,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            raised_by: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("ticket/fetchTicket", {
                            perPage: this.perPage,
                            currentPage: 1,
                            search_term: this.search_term,
                            priority: this.priority,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            raised_by: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("ticket/fetchTicket", {
                            perPage: this.perPage,
                            currentPage: 1,
                            search_term: this.search_term,
                            priority: this.priority,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            raised_by: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    } else {
                        this.$store.dispatch("ticket/fetchTicket", {
                            perPage: this.perPage,
                            currentPage: 1,
                            search_term: this.search_term,
                            priority: this.priority,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            raised_by: this.selectedEmployee
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false;
                        });
                    }
                }

            }

        }
    },

    computed: {
        ...mapState({
            items: state => state.ticket.ticket_list,
            count: state => state.ticket.all_ticket,
            user_list: state => state.employee.employee_list,
            ticket_reply: state => state.ticket.ticket_reply,
            loading: state => state.ticket.ticketLoading,
            error: state => state.ticket.ticketError,
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation,
        }),

        fullName() {
            if (this.ticket_details.raised_by) {
                return this.ticket_details.raised_by.first_name + ' ' + this.ticket_details.raised_by.last_name;
            } else {
                return '';
            }
        },

        formattedEmployee() {
            return [{
                    value: '',
                    text: 'Select Employee',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [{
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [{
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        },

        formattedOptions() {
            return [{
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses_new.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },

        formattedPriorities() {
            return [{
                    value: '',
                    text: 'Select Priority',
                    disabled: true
                },
                ...this.priorities_new.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        }
    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("department/fetchDepartments");
        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {

                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitial();
            } else {

                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitial();
            }
        }

        
    },

    methods: {
        fetchInitial() {
            this.$store.dispatch("ticket/fetchTicket", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                search_term: this.search_term,
                priority: this.priority,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                raised_by: this.selectedEmployee
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        },
        clearFilter() {
            this.filterShow = false,
                this.search_term = null,
                this.statusSelected = '',
                this.selectedEmployee = '',
                this.selectedDesignation = '',
                this.selectedDepartment = '',
                this.start_date = null,
                this.end_date = null,
                this.priority = ''
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },

        TicketDetails(type) {
            this.ticket_details.id = type.id
            this.ticket_details.created_date = type.created_date
            this.ticket_details.status = type.status
            this.ticket_details.priority = type.priority
            this.ticket_details.subject = type.subject
            this.ticket_details.details = type.details
            this.ticket_details.refer_to = type.refer_to
            this.ticket_details.raised_by = type.raised_by
        },

        handleAllocationDeleteOk() {
            this.$store.dispatch("ticket/ticketDeleteRefer", {
                id: this.my_reply.id,
                refer_id: this.temp_id,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                search_term: this.search_term,
                priority: this.priority,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                raised_by: this.selectedEmployee,
                all: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        deletetAllocation(id) {
            this.temp_id = id
        },

        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.refer_to
            this.$store.dispatch("ticket/fetchTicketReply", this.my_reply.id)
        },

        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("ticket/ticketAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                search_term: this.search_term,
                priority: this.priority,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                raised_by: this.selectedEmployee,
                all: true
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        editTicket(ticket) {
            this.edit_ticket.id = ticket.id
            this.edit_ticket.subject = ticket.subject
            this.edit_ticket.refer_to = ticket.refer_to.id
        },

        resetEditModal() {
            this.edit_grievance.subject = ''
            this.edit_grievance.date = null
            this.edit_grievance.refering_to = null
            this.edit_grievance.status = ''
            this.edit_grievance.details = ''
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            if (!Array.isArray(this.edit_ticket.refer_to)) {
                this.edit_ticket.refer_to = [];
            }
            this.assignedMembers.forEach(item => {
                this.edit_ticket.refer_to.push(item.id);
            });

            this.$store.dispatch("ticket/updateTicket", {
                id: this.edit_ticket.id,
                subject: this.edit_ticket.subject,
                refer_to: this.edit_ticket.refer_to

            }).then(response => {
                console.log(this.edit_holiday)
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_ticket.subject = '',
                        this.edit_ticket.details = '',
                        this.edit_ticket.refer_to = [],
                        this.assignedMembers = []

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        deleteTicket(id) {
            this.ticket_id = id
        },

        handleDeleteOk() {
            this.$store.dispatch("ticket/deleteTicket", {
                delete: this.ticket_id,

                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                search_term: this.search_term,
                priority: this.priority,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                raised_by: this.selectedEmployee,
                all: true,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        getStatus(status, id) {
            this.update_ticket_id = id
            this.selectedStatus = status
        },

        handleStatusOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleStatusSubmit()
        },

        handleStatusSubmit() {
            this.$store.dispatch("ticket/updateTicketStatus", {
                id: this.update_ticket_id,
                status_for_change: this.selectedStatus,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.search_term,
                priority: this.priority,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                raised_by: this.selectedEmployee,
                all: true,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-status')
            })
        },

        getPriority(priority, id) {
            this.update_priority_id = id
            this.selectedPriority = priority
        },

        handlePriorityOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handlePrioritySubmit()
        },

        handlePrioritySubmit() {
            this.$store.dispatch("ticket/updateTicketPriority", {
                id: this.update_priority_id,
                priority_for_change: this.selectedPriority,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.search_term,
                priority: this.priority,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                raised_by: this.selectedEmployee,
                all: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-priority')
            })
        },

        handleReplySubmit() {
            if (this.my_reply.reply) {
                this.$store.dispatch("ticket/addTicketReply", {
                    ticket: this.my_reply.id,
                    reply: this.my_reply.reply
                }).then(response => {

                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.my_reply.id = '',
                            this.my_reply.reply = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-scrollable')
                })
            }
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.img-30 {
    width: 30px !important;
    height: 30px !important;
}
</style>
