<template>
    <div>
        <Breadcrumbs main="My Workspace" title="Apply for Leave" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">
                                <div class="justify-content-center align-items-center" v-if="leave_remaining && !loading && !error">
                                    <div class="task-board row">
                                        <div class="d-flex flex-row ">
                                            <b-card style="width: 8rem; background-color: #fff0f0; cursor: pointer;" class="ml-4" v-for="(value, key) in leave_remaining" :key="key">
                                                <b-card-text style="display: flex; justify-content: center; align-items: center;" @click="leavePick(value.id)">
                                                    <div style="display: flex; flex-direction: column;">
                                                        <span style="text-align: center; font-weight: 500; font-size: 12px;" v-b-tooltip.hover :title="value.name"> {{ value.name.substring(0, 11) }} </span>
                                                        <span style="text-align: center; font-weight: 600; font-size: 14px;"> {{ value.days }} days</span>
                                                    </div>
                                                </b-card-text>
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="loader-box">
                                        <div class="loader-10"></div>
                                    </div>
                                </div>
    
                                <div class="row mt-4">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Leave Type</label>
                                            <select style="height: 44px;" class="form-control digits" v-model="leave.leave_type">
                                                <option v-for="(type, index) in leave_type" :key="index" :value="type">{{ type.name }}</option>
                                            </select>
                                        </div>
                                    </div>
    
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Referring To</label>
                                            <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row" v-if="leave.start_date && leave.end_date">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Total Leave Days</label>
                                            <input style="height: 43px;" type="number" v-model="calculateWorkingDays" class="form-control" placeholder="Calculated Days" readonly />
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Subject</label>
                                            <!-- <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="leave.subject"></datepicker> -->
                                            <input style="height: 43px;" type="text" v-model="leave.subject" class="form-control" placeholder="Enter subject" />
                                        </div>
                                    </div>
    
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Start date</label>
                                                    <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" ref="datepicker"
                                                    v-model="leave.start_date"></datepicker>
                                                </div>
                                            </div>
    
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>End Date</label>
                                                    <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" ref="datepickerend" :disabled-dates="endDateDisabled" v-model="leave.end_date"></datepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Reason</label>
                                            <b-form-textarea v-model="leave.reason" rows="3" placeholder="Enter your reason"></b-form-textarea>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mb-0">
                                            <button @click="onSubmit" class="btn btn-success mr-3" :disabled="isButtonDisabled">Submit</button>
                                            <router-link to="/my-workspace/apply-for-leave">
                                                <button class="btn btn-danger" href="#">Go Back</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
    </template>
    
    <script>
    import Datepicker from 'vuejs-datepicker';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    import moment from "moment";
    import Multiselect from 'vue-multiselect';
    import { mapState } from 'vuex';
    import js from '../../../auth/js';
    import treeVue from '../../advance/tree.vue';
    
    export default {
        components: {
            vueDropzone: vue2Dropzone,
            Datepicker,
            Multiselect
        },
    
        data() {
            return {
                isButtonDisabled: false,
                leave: {
                    start_date: null,
                    end_date: null,
                    subject: '',
                    reason: '',
                    leave_type: '',
                    refer_to: [],
                    total_days: null
                },
                calculatedDays: null,
                leaveFor: null,
                calculatedDate: null,
                dateRange: null,
                assignedMembers: []
            };
        },
    
        watch: {
            'leave.leave_type': function (newLeaveType, oldLeaveType) {
                this.leave.start_date = null,
                this.leave.end_date = null
            },
    
            'leave.start_date': function (newStartDate, oldStartDate) {
                if (this.leave.end_date) {
                    this.leave.end_date = null
                }
                this.calculateEndDate();
            },
    
            'leave.end_date': function (newEndDate, oldEndDate) {
                const newEndDateTimestamp = new Date(newEndDate).getTime();
                const calculatedDateTimestamp = new Date(this.calculatedDate).getTime();
    
                if (newEndDateTimestamp > calculatedDateTimestamp) {
                    // Show the alert
                    Swal.fire({
                        title: "Error!",
                        text: 'End Date Exceeds Allowable Duration.',
                        icon: "error"
                    }).then(() => {
                        this.leave.end_date = null;
                        this.leave.start_date = null;
                        this.$nextTick(() => {
                            this.leave.end_date = null;
                            this.leave.start_date = null;
                            this.calculatedDate = null
                        });
                    });
                }
            }
        },
    
        methods: {
            leavePick(id) {
                const selectedLeave = this.leave_type.find(leave => leave.id === id);
                if (selectedLeave) {
                    this.leave.leave_type = selectedLeave
                } else {}
            },
            calculateEndDate() {
                if (this.leave.leave_type && this.leave.start_date) {
                    const remainingLeave = this.leave_remaining[this.leave.leave_type.name];
                    const leaveRemaining = remainingLeave.days;
    
                    if (leaveRemaining) {
                        let daysToAdd = leaveRemaining - 1;
    
                        let startDate = new Date(this.leave.start_date);
                        let endDate = new Date(startDate);
    
                        const holidayList = this.holiday_list.holidays.map(holiday => holiday.value);
    
                        while (daysToAdd > 0) {
                            endDate.setDate(endDate.getDate() + 1);
                            const day = endDate.getDay();
    
                            if (!holidayList.includes(day)) {
                                daysToAdd--;
                            }
                        }
    
                        this.calculatedDate = endDate;
    
                    }
    
                }
            },
            // calculateWorkingDays() {
            //     if (this.leave.start_date && this.leave.end_date) {
            //         let startDate = new Date(this.leave.start_date);
            //         let endDate = new Date(this.leave.end_date);
            //         let workingDaysCount = 0;
    
            //         let currentDate = new Date(startDate);
            //         const holidayList = this.holiday_list.holidays.map(holiday => holiday.value);
    
            //         while (currentDate <= endDate) {
            //             const day = currentDate.getDay();
    
            //             if (!holidayList.includes(day)) {
            //                 workingDaysCount++;
            //             }
    
            //             currentDate.setDate(currentDate.getDate() + 1);
            //         }
    
            //         this.leave.total_days = workingDaysCount;
            //         console.log(this.leave.total_days)
            //     } else {
    
            //         this.leave.total_days = 0; 
            //         console.log(this.leave.total_days)
            //     }
            // },
    
            uploadFile(e) {
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.leave.project_file = e.target.result;
                };
            },
    
            onSubmit(event) {
                this.isButtonDisabled = true
                if (this.leave.start_date == null || this.leave.end_date == null) {
                    return
                }
                if (this.leave.start_date !== null) {
                    this.leave.start_date = moment(String(this.leave.start_date)).format("YYYY-MM-DD");
                }
                if (this.leave.end_date !== null) {
                    this.leave.end_date = moment(String(this.leave.end_date)).format("YYYY-MM-DD");
                }
    
                const idArray = this.assignedMembers.map(item => this.leave.refer_to.push(
                    item.id
                ));
    
                this.leave.total_days = this.calculateWorkingDays
                this.leave.leave_type = this.leave.leave_type.id
    
                this.$store.dispatch("leave/leaveApply", this.leave).then(response => {
                    if (response.code == 200) {
                        this.isButtonDisabled = false
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.leave.start_date = null
                        this.leave.end_date = null
                        this.leave.subject = null
                        this.leave.reason = null
                        this.leave.leave_type = null
                        this.leave.total_days = null
                        this.leave.refer_to = null
    
                        setTimeout(() => {
                            this.$router.push('/my-workspace/apply-for-leave')
                        }, 3000)
    
                    } else {
                        this.isButtonDisabled = false
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
        },
    
        computed: {
            ...mapState({
                leave_type: state => state.leave.leaveTypeList,
                loading: state => state.leave.submitLoading,
                error: state => state.leave.submitError,
                user_list: state => state.employee.employee_list,
                leave_remaining: state => state.leave.leave_remaining,
                holiday_list: state => state.leave.holidayList
            }),
    
            RangeSeparatedDate() {
                if (this.dateRange) {
                    const dateParts = this.dateRange.split('-');
                    const year = dateParts[0];
                    const month = dateParts[1];
                    const day = dateParts[2];
                    return {
                        year,
                        month,
                        day
                    };
                } else {
                    return {
                        year: null,
                        month: null,
                        day: null
                    };
                }
            },
    
            // disabledDates() {
            //     const holidayDays = this.holiday_list.holidays.map(holiday => holiday.value);
    
            //     // Generate disabled dates for a wide range of years (adjust the range as needed)
            //     let disabledDatesArray = [];
            //     let currentDate = new Date();
    
            //     // Iterate over the next few years (adjust range as necessary)
            //     for (let i = 0; i < 365 * 3; i++) {
            //         const date = new Date(currentDate);
            //         const day = date.getDay();
    
            //         // Check if the day is in the holiday list
            //         if (holidayDays.includes(day)) {
            //             disabledDatesArray.push(new Date(date));
            //         }
    
            //         // Increment date by one day
            //         currentDate.setDate(currentDate.getDate() + 1);
            //     }
    
            //     // Return the object with disabled dates
            //     return {
            //         dates: disabledDatesArray
            //     };
            // },
    
            calculateWorkingDays() {
                if (this.leave.start_date && this.leave.end_date) {
                    let startDate = new Date(this.leave.start_date);
                    let endDate = new Date(this.leave.end_date);
                    let workingDaysCount = 0;
    
                    let currentDate = new Date(startDate);
                    const holidayList = this.holiday_list.holidays.map(holiday => holiday.value);
    
                    // Loop from start date to end date
                    while (currentDate <= endDate) {
                        const day = currentDate.getDay();
                        // Check if it's not a holiday
                        if (!holidayList.includes(day)) {
                            workingDaysCount++;
                        }
                        // Increment to the next day
                        currentDate.setDate(currentDate.getDate() + 1);
                    }
                    return workingDaysCount; // Return the working days count
                } else {
                    return 0; // No valid dates, return 0
                }
            },
    
            endDateDisabled() {
                return {
                    to: this.leave.start_date ? new Date(this.leave.start_date) : null
                };
            }
        },
    
        mounted() {
            this.$store.dispatch("leave/fetchLeaveType", {
                active_list: true
            });
            this.$store.dispatch("employee/fetchEmployeeList");
            this.$store.dispatch("leave/fetchLeaveRemaining");
            this.$store.dispatch("leave/fetchHolidayList");
            this.$nextTick(() => {
                const input = this.$refs.datepicker.$el.querySelector('input');
                if (input) {
                    input.style.height = '43px';
                }
            });
            this.$nextTick(() => {
                const input = this.$refs.datepickerend.$el.querySelector('input');
                if (input) {
                    input.style.height = '43px';
                }
            });
        }
    };
    </script>
    
    <style scoped>
    .task-board {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }
    
    ::-webkit-scrollbar {
        height: 5px;
        width: 8px;
    }
    
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    </style>
    