<template>
<div>
    <div style="width: 100%;" v-if="!loading && !error">
        <Breadcrumbs main="Dashboard" title="Home" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">

            <div class="row" v-if="user_access.is_employee == true">
                <div class="col-md-12 project-list">
                    <div class="card">
                        <div class="row m-0">
                            <div class="col-sm-12 p-0">
                                <b-tabs content-class="mt-3" v-model="tabIndex">
                                    <b-tab title="My Dashboard" :title-link-class="linkClass(0)">
                                        <template v-slot:title>
                                            <feather type="target"></feather> My Dashboard
                                        </template>

                                        <div class="row size-column">
                                            <div class="col-xl-7 box-col-12 xl-100" v-if="user_access.is_employee == true">
                                                <div class="row dash-chart">
                                                    <div class="col-xl-6 box-col-6 col-md-6">
                                                        <div>
                                                            <px-card class="card-no-border small-widget">
                                                                <div class="profile_body" style="min-height: 290px">
                                                                    <div style="padding:40px;">
                                                                        <div class="d-flex flex-row">
                                                                            <div class="bg-profile">
                                                                                <div class="image-container">
                                                                                    <img v-if="details.additional_info[0]" class="rounded-image" :src="host + details.additional_info[0].photo" alt="Profile Photo">
                                                                                    <img v-else class="rounded-image" src="" alt="Profile Photo">
                                                                                </div>
                                                                            </div>
                                                                            <div style="margin-left: 120px;">
                                                                                <div class="profile-info">
                                                                                    <div>
                                                                                        <span class="font-weight-bold text-dark mt-3" style="font-size: 25px;">{{ details.user_object.first_name }} {{ details.user_object.last_name }}</span>
                                                                                        <div style="display: flex; flex-direction: row; gap: 10px;" class="mt-2" v-if="details.additional_info[0]">
                                                                                            <h7 class="text-secondary">{{ details.additional_info[0].department.name }} | </h7>
                                                                                            <h7 class="text-secondary">{{ details.additional_info[0].designation.title }}</h7>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="mt-2">
                                                                                            <button class="btn btn-success" @click="attend()" v-if="timeSheet.status === 'OUT' || timeSheet.status == null">Punch In</button>
                                                                                            <button class="btn btn-danger" @click="attend()" v-if="timeSheet.status === 'IN'">Punch Out</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </px-card>

                                                        </div>
                                                    </div>

                                                    <div class="col-xl-6 box-col-6 col-md-6">
                                                        <div class="card o-hidden">
                                                            <div class="card-body">
                                                                <div class="media">
                                                                    <div class="media-body">
                                                                        <p class="f-w-500 font-roboto">
                                                                            Today Task
                                                                        </p>
                                                                        <div class="progress-box d-flex justify-content-between">
                                                                            <h4 class="f-w-500 mb-0 f-20">
                                                                                <span class="counter">{{formattedDate }}</span>
                                                                            </h4>
                                                                            <div class="">
                                                                                <div id="riskfactorchart">
                                                                                    <apexchart height="240" type="radialBar" :options="apexRiskchart.options" :series="apexRiskchart.series"></apexchart>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6 box-col-6 col-lg-12 col-md-6">
                                                        <div class="card o-hidden">
                                                            <div class="card-header card-no-border pb-0">
                                                                <div class="ecommerce-widgets media">
                                                                    <div class="media-body">
                                                                        <div class="pt-4">
                                                                            <h7 class="text-secondary">Have You Submitted</h7>
                                                                            <h5 class="text-black mb-2">EOD Report?</h5>
                                                                        </div>
                                                                        <router-link to="/my-workspace/eod/eod-report">
                                                                            <button class="btn btn-success" style="margin-top:25px">Submit NOW!</button>
                                                                        </router-link>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-body p-0 d-flex align-content-end justify-content-end">
                                                                <!-- image -->
                                                                <img src="../../assets/images/dashboard/eod-submit.png" alt="">
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-xl-6 box-col-6 col-lg-12 col-md-6">
                                                        <div class="card o-hidden">
                                                            <div class="card-header card-no-border pb-0">
                                                                <div class="ecommerce-widgets media">
                                                                    <div class="media-body">
                                                                        <h5 class="text-black mb-2">Personal Growth</h5>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="card-body p-0">
                                                                <div id="chart-container">
                                                                    <apexchart height="200" :options="chartOptions" :series="chartOptions.series"></apexchart>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="col-xl-5 box-col-12 xl-100" v-if="user_access.is_employee == true">
                                                <div class="row">
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4 mt-md-2 mt-lg-4 mt-xl-0">
                                                        <router-link to="/project/my-project">
                                                            <div class="card small-widget">
                                                                <div class="card-body success"><span class="f-light">Initiatives</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ myDashboardCount.project_count }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">
                                                                        <svg class="stroke-icon svg-fill" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px">
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 6C3.25 4.45831 4.48029 3.26447 6.00774 2.50075C7.58004 1.7146 9.69967 1.25 12 1.25C14.3003 1.25 16.42 1.7146 17.9923 2.50075C19.5197 3.26447 20.75 4.45831 20.75 6V18C20.75 19.5417 19.5197 20.7355 17.9923 21.4992C16.42 22.2854 14.3003 22.75 12 22.75C9.69967 22.75 7.58004 22.2854 6.00774 21.4992C4.48029 20.7355 3.25 19.5417 3.25 18V6ZM4.75 6C4.75 5.33255 5.31057 4.52639 6.67856 3.84239C8.00168 3.18083 9.88205 2.75 12 2.75C14.118 2.75 15.9983 3.18083 17.3214 3.84239C18.6894 4.52639 19.25 5.33255 19.25 6C19.25 6.66745 18.6894 7.47361 17.3214 8.15761C15.9983 8.81917 14.118 9.25 12 9.25C9.88205 9.25 8.00168 8.81917 6.67856 8.15761C5.31057 7.47361 4.75 6.66745 4.75 6ZM4.75 18C4.75 18.6674 5.31057 19.4736 6.67856 20.1576C8.00168 20.8192 9.88205 21.25 12 21.25C14.118 21.25 15.9983 20.8192 17.3214 20.1576C18.6894 19.4736 19.25 18.6674 19.25 18V14.7072C18.8733 15.0077 18.4459 15.2724 17.9923 15.4992C16.42 16.2854 14.3003 16.75 12 16.75C9.69967 16.75 7.58004 16.2854 6.00774 15.4992C5.55414 15.2724 5.12675 15.0077 4.75 14.7072V18ZM19.25 8.70722V12C19.25 12.6674 18.6894 13.4736 17.3214 14.1576C15.9983 14.8192 14.118 15.25 12 15.25C9.88205 15.25 8.00168 14.8192 6.67856 14.1576C5.31057 13.4736 4.75 12.6674 4.75 12V8.70722C5.12675 9.00772 5.55414 9.27245 6.00774 9.49925C7.58004 10.2854 9.69967 10.75 12 10.75C14.3003 10.75 16.42 10.2854 17.9923 9.49925C18.4459 9.27245 18.8733 9.00772 19.25 8.70722Z"></path>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </router-link>

                                                        <router-link to="/my-workspace/my-assets">
                                                            <div class="card small-widget">
                                                                <div class="card-body warning"><span class="f-light">Asset</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ myDashboardCount.asset_count }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">
                                                                        <svg class="stroke-icon svg-fill" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px">

                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path d="M8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12H16C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10H8Z"></path>
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M23 4C23 2.34315 21.6569 1 20 1H4C2.34315 1 1 2.34315 1 4V5C1 6.30622 1.83481 7.41746 3 7.82929V20C3 21.6569 4.34315 23 6 23H18C19.6569 23 21 21.6569 21 20V7.82929C22.1652 7.41746 23 6.30622 23 5V4ZM20 6H4C3.44772 6 3 5.55228 3 5V4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V5C21 5.55228 20.5523 6 20 6ZM5 20V8H19V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20Z"></path>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </router-link>

                                                        <router-link to="/my-workspace/apply-for-leave">
                                                            <div class="card small-widget">
                                                                <div class="card-body secondary"><span class="f-light">Leave</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ myDashboardCount.leave_count }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">
                                                                        <svg class="stroke-icon svg-fill" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48" width="30px" height="30px">

                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path fill="#FFCDD2" d="M5,38V14h38v24c0,2.2-1.8,4-4,4H9C6.8,42,5,40.2,5,38z"></path>
                                                                                <path fill="#F44336" d="M43,10v6H5v-6c0-2.2,1.8-4,4-4h30C41.2,6,43,7.8,43,10z"></path>
                                                                                <g fill="#B71C1C">
                                                                                    <circle cx="33" cy="10" r="3"></circle>
                                                                                    <circle cx="15" cy="10" r="3"></circle>
                                                                                </g>
                                                                                <g fill="#BDBDBD">
                                                                                    <path d="M33,3c-1.1,0-2,0.9-2,2v5c0,1.1,0.9,2,2,2s2-0.9,2-2V5C35,3.9,34.1,3,33,3z"></path>
                                                                                    <path d="M15,3c-1.1,0-2,0.9-2,2v5c0,1.1,0.9,2,2,2s2-0.9,2-2V5C17,3.9,16.1,3,15,3z"></path>
                                                                                </g>
                                                                                <path fill="#F44336" d="M22.2,35.3c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.5s0.3-0.3,0.5-0.3c0.2-0.1,0.5-0.1,0.7-0.1 s0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.3s0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.5 c-0.2,0.1-0.3,0.3-0.6,0.3S24.3,37,24,37s-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5 C22.3,35.8,22.2,35.6,22.2,35.3z M25.3,31h-2.6l-0.4-11h3.3L25.3,31z"></path>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </router-link>

                                                        <router-link to="/my-workspace/ticket/my-tickets">

                                                            <div class="card small-widget">
                                                                <div class="card-body warning"><span class="f-light">Assignment Refer</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ myDashboardCount.ticket_referred_count }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">

                                                                        <svg class="stroke-icon svg-fill" height="30px" width="30px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve" fill="#000000">

                                                                            <g id="SVGRepo_iconCarrier">

                                                                                <g>
                                                                                    <path class="st0 fill-success" d="M92.574,294.24V124.336H43.277C19.449,124.336,0,144.213,0,168.467v206.44 c0,24.254,19.449,44.133,43.277,44.133h62v45.469c0,3.041,1.824,5.777,4.559,6.932c2.736,1.154,5.957,0.486,8.023-1.641 l49.844-50.76h106.494c23.828,0,43.279-19.879,43.279-44.133v-0.061H172.262C128.314,374.846,92.574,338.676,92.574,294.24z"></path>
                                                                                    <path class="st0 fill-danger" d="M462.717,40H172.26c-27.105,0-49.283,22.59-49.283,50.197v204.037c0,27.61,22.178,50.199,49.283,50.199 h164.668l75.348,76.033c2.399,2.442,6.004,3.172,9.135,1.852c3.133-1.322,5.176-4.434,5.176-7.887v-69.998h36.131 c27.106,0,49.283-22.59,49.283-50.199V90.197C512,62.59,489.822,40,462.717,40z M369.156,280.115H195.92v-24.316h173.236V280.115z M439.058,204.129H195.92v-24.314h243.138V204.129z M439.058,128.143H195.92v-24.315h243.138V128.143z"></path>
                                                                                </g>
                                                                            </g>
                                                                        </svg>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </router-link>
                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4 mt-md-2 mt-lg-4 mt-xl-0">
                                                        <router-link to="/my-workspace/ticket/raise-a-ticket">
                                                            <div class="card small-widget">
                                                                <div class="card-body success"><span class="f-light">Assignment Raised</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ myDashboardCount.ticket_raised_count }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">
                                                                        <!-- <svg class="stroke-icon svg-fill" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px">
                                                                                                <g id="SVGRepo_iconCarrier">
                                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 6C3.25 4.45831 4.48029 3.26447 6.00774 2.50075C7.58004 1.7146 9.69967 1.25 12 1.25C14.3003 1.25 16.42 1.7146 17.9923 2.50075C19.5197 3.26447 20.75 4.45831 20.75 6V18C20.75 19.5417 19.5197 20.7355 17.9923 21.4992C16.42 22.2854 14.3003 22.75 12 22.75C9.69967 22.75 7.58004 22.2854 6.00774 21.4992C4.48029 20.7355 3.25 19.5417 3.25 18V6ZM4.75 6C4.75 5.33255 5.31057 4.52639 6.67856 3.84239C8.00168 3.18083 9.88205 2.75 12 2.75C14.118 2.75 15.9983 3.18083 17.3214 3.84239C18.6894 4.52639 19.25 5.33255 19.25 6C19.25 6.66745 18.6894 7.47361 17.3214 8.15761C15.9983 8.81917 14.118 9.25 12 9.25C9.88205 9.25 8.00168 8.81917 6.67856 8.15761C5.31057 7.47361 4.75 6.66745 4.75 6ZM4.75 18C4.75 18.6674 5.31057 19.4736 6.67856 20.1576C8.00168 20.8192 9.88205 21.25 12 21.25C14.118 21.25 15.9983 20.8192 17.3214 20.1576C18.6894 19.4736 19.25 18.6674 19.25 18V14.7072C18.8733 15.0077 18.4459 15.2724 17.9923 15.4992C16.42 16.2854 14.3003 16.75 12 16.75C9.69967 16.75 7.58004 16.2854 6.00774 15.4992C5.55414 15.2724 5.12675 15.0077 4.75 14.7072V18ZM19.25 8.70722V12C19.25 12.6674 18.6894 13.4736 17.3214 14.1576C15.9983 14.8192 14.118 15.25 12 15.25C9.88205 15.25 8.00168 14.8192 6.67856 14.1576C5.31057 13.4736 4.75 12.6674 4.75 12V8.70722C5.12675 9.00772 5.55414 9.27245 6.00774 9.49925C7.58004 10.2854 9.69967 10.75 12 10.75C14.3003 10.75 16.42 10.2854 17.9923 9.49925C18.4459 9.27245 18.8733 9.00772 19.25 8.70722Z"></path>
                                                                                                </g>
                                                                                            </svg> -->

                                                                        <svg class="stroke-icon svg-fill" height="30px" width="30px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve" fill="#000000">

                                                                            <g id="SVGRepo_iconCarrier">

                                                                                <g>
                                                                                    <path class="st0 fill-danger" d="M92.574,294.24V124.336H43.277C19.449,124.336,0,144.213,0,168.467v206.44 c0,24.254,19.449,44.133,43.277,44.133h62v45.469c0,3.041,1.824,5.777,4.559,6.932c2.736,1.154,5.957,0.486,8.023-1.641 l49.844-50.76h106.494c23.828,0,43.279-19.879,43.279-44.133v-0.061H172.262C128.314,374.846,92.574,338.676,92.574,294.24z"></path>
                                                                                    <path class="st0 fill-success" d="M462.717,40H172.26c-27.105,0-49.283,22.59-49.283,50.197v204.037c0,27.61,22.178,50.199,49.283,50.199 h164.668l75.348,76.033c2.399,2.442,6.004,3.172,9.135,1.852c3.133-1.322,5.176-4.434,5.176-7.887v-69.998h36.131 c27.106,0,49.283-22.59,49.283-50.199V90.197C512,62.59,489.822,40,462.717,40z M369.156,280.115H195.92v-24.316h173.236V280.115z M439.058,204.129H195.92v-24.314h243.138V204.129z M439.058,128.143H195.92v-24.315h243.138V128.143z"></path>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </router-link>
                                                        <router-link to="/my-workspace/eod/my-eod-report">
                                                            <div class="card small-widget">
                                                                <div class="card-body warning"><span class="f-light">EOD</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ myDashboardCount.eod_count }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">

                                                                        <svg class="stroke-icon svg-fill" fill="#dc3545" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px">

                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path d="M8 10.25C8 9.83579 8.33579 9.5 8.75 9.5H18.75C19.1642 9.5 19.5 9.83579 19.5 10.25C19.5 10.6642 19.1642 11 18.75 11H8.75C8.33579 11 8 10.6642 8 10.25Z" fill="#dc3545"></path>
                                                                                <path d="M8 14.75C8 14.3358 8.33579 14 8.75 14H18.75C19.1642 14 19.5 14.3358 19.5 14.75C19.5 15.1642 19.1642 15.5 18.75 15.5H8.75C8.33579 15.5 8 15.1642 8 14.75Z" fill="#dc3545"></path>
                                                                                <path d="M8.75 18.5C8.33579 18.5 8 18.8358 8 19.25C8 19.6642 8.33579 20 8.75 20H13.25C13.6642 20 14 19.6642 14 19.25C14 18.8358 13.6642 18.5 13.25 18.5H8.75Z" fill="#dc3545"></path>
                                                                                <path d="M14 2C14.4142 2 14.75 2.33579 14.75 2.75V4H18.5V2.75C18.5 2.33579 18.8358 2 19.25 2C19.6642 2 20 2.33579 20 2.75V4H20.75C21.9926 4 23 5.00736 23 6.25V19.2459C23 19.4448 22.921 19.6356 22.7803 19.7762L17.2762 25.2803C17.1355 25.421 16.9447 25.5 16.7458 25.5H6.75C5.50736 25.5 4.5 24.4926 4.5 23.25V6.25C4.5 5.00736 5.50736 4 6.75 4H8V2.75C8 2.33579 8.33579 2 8.75 2C9.16421 2 9.5 2.33579 9.5 2.75V4H13.25V2.75C13.25 2.33579 13.5858 2 14 2ZM6 6.25V23.25C6 23.6642 6.33579 24 6.75 24H15.9958V20.7459C15.9958 19.5032 17.0032 18.4959 18.2458 18.4959H21.5V6.25C21.5 5.83579 21.1642 5.5 20.75 5.5H6.75C6.33579 5.5 6 5.83579 6 6.25ZM18.2458 19.9959C17.8316 19.9959 17.4958 20.3317 17.4958 20.7459V22.9394L20.4393 19.9959H18.2458Z" fill="#dc3545"></path>
                                                                            </g>
                                                                        </svg>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </router-link>

                                                        <router-link to="/my-workspace/grievance">
                                                            <div class="card small-widget">
                                                                <div class="card-body secondary"><span class="f-light">Grievance</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ myDashboardCount.grievance_count }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">

                                                                        <svg class="stroke-icon svg-fill" fill="#dc3545" viewBox="-2 0 19 19" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px">
                                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path d="M14.032 5.286v7.276a1.112 1.112 0 0 1-1.108 1.108H8.75l-1.02 1.635a.273.273 0 0 1-.503 0l-1.02-1.635h-4.13a1.112 1.112 0 0 1-1.109-1.108V5.286a1.112 1.112 0 0 1 1.108-1.108h10.848a1.112 1.112 0 0 1 1.108 1.108zM8.206 11.34a.706.706 0 1 0-.706.705.706.706 0 0 0 .706-.705zm-1.26-1.83a.554.554 0 1 0 1.108 0V6.275a.554.554 0 1 0-1.108 0z"></path>
                                                                            </g>
                                                                        </svg>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </router-link>

                                                        <router-link to="/my-workspace/my-requisition">
                                                            <div class="card small-widget">
                                                                <div class="card-body warning"><span class="f-light">Requisition</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ myDashboardCount.requisition_count }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">

                                                                        <svg class="stroke-icon svg-fill" height="30px" width="30px" fill="#dc3545" viewBox="0 0 24 24" id="receipt-check" data-name="Line Color" xmlns="http://www.w3.org/2000/svg">
                                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <polyline id="secondary" points="10 10 12 12 16 8" style="fill: none; stroke: #51bb25; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline>
                                                                                <path id="primary" d="M21,4V18.5a2.5,2.5,0,0,1-5,0V16H5V4A1,1,0,0,1,6,3H20A1,1,0,0,1,21,4ZM16,18.5V16H3v2.5A2.5,2.5,0,0,0,5.5,21h13A2.5,2.5,0,0,1,16,18.5Z" style="fill: none; stroke: #dc3545; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path>
                                                                            </g>
                                                                        </svg>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </router-link>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </b-tab>

                                    <b-tab title="Operational Dashboard" :title-link-class="linkClass(1)">
                                        <template v-slot:title>
                                            <feather type="check-circle"></feather> Operational Dashboard
                                        </template>

                                        <div class="row size-column">
                                            <div class="col-xl-7 box-col-12 xl-100">
                                                <div class="row dash-chart">
                                                    <div class="col-xl-12 box-col-12 col-md-12">
                                                    
                                                        <div class="card" v-if="hasViewPermission('view_employee')">
                                                            <div class="card-body" id="example-simple">
                                                                <calendar-view :show-event-times="showEventTimes" :show-date="showDate" :events="combinedEvents" :enable-drag-drop="false" @drop-on-date="onDrop" @click-event="onClickEvent" class="theme-default holiday-us-traditional holiday-us-official">
                                                                    <calendar-view-header slot="header" slot-scope="t" :header-props="t.headerProps" @input="setShowDate" @click-event="onClickEvent" />
                                                                </calendar-view>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-xl-6 box-col-6 col-lg-12 col-md-6" v-if="hasViewPermission('view_employee')">

                                                        <router-link to="/hr/organization/employee">
                                                            <div class="card small-widget">
                                                                <div class="card-header card-no-border pb-4">
                                                                    <div class="header-top">
                                                                        <h5>Total Employee</h5>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body pt-0">
                                                                    <div class="user-list">
                                                                        <div class="d-flex align-items-center" style="gap:10px;">
                                                                            <div class="user-icon primary">
                                                                                <div class="user-box">
                                                                                    <svg class="stroke-icon svg-fill" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512.001 512.001" xml:space="preserve">

                                                                                        <g id="SVGRepo_iconCarrier">
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path d="M375.071,86.028c-11.366,0-22.143,2.561-31.796,7.122c3.686,4.748,6.998,9.802,9.882,15.121 c2.828,5.216,5.244,10.688,7.214,16.364c3.928,11.321,6.069,23.469,6.069,36.109c0,12.639-2.141,24.788-6.069,36.108 c-1.969,5.678-4.386,11.147-7.214,16.364c-2.884,5.319-6.195,10.372-9.882,15.121c9.653,4.56,20.43,7.123,31.796,7.123 c41.199,0.002,74.716-33.516,74.716-74.714C449.787,119.545,416.27,86.028,375.071,86.028z"></path>
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path d="M375.071,271.182c-4.42,0-8.827,0.218-13.206,0.641c6.82,5.311,13.237,11.115,19.187,17.369 c6.005,6.311,11.53,13.079,16.534,20.237c16.349,23.386,27.066,50.987,30.146,80.823c0.607,5.873,0.92,11.83,0.92,17.86 c0,6.261-1.09,12.27-3.072,17.86h68.56c9.864,0,17.86-7.998,17.86-17.86C512.001,332.608,450.574,271.182,375.071,271.182z"></path>
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path d="M151.632,196.855c-3.928-11.32-6.069-23.469-6.069-36.108c0-12.64,2.141-24.788,6.069-36.109 c1.971-5.68,4.386-11.15,7.214-16.366c2.884-5.319,6.195-10.372,9.882-15.121c-9.653-4.56-20.43-7.122-31.796-7.122 c-41.199,0-74.716,33.517-74.716,74.716c0,41.198,33.517,74.716,74.716,74.716c11.366,0,22.143-2.562,31.796-7.123 c-3.686-4.749-6.998-9.802-9.882-15.121C156.018,208.002,153.602,202.532,151.632,196.855z"></path>
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path d="M136.93,271.182C61.427,271.182,0,332.608,0,408.112c0,9.863,7.997,17.86,17.86,17.86h68.56 c-1.981-5.59-3.071-11.6-3.071-17.86c0-6.031,0.313-11.988,0.919-17.86c3.08-29.836,13.797-57.437,30.146-80.823 c5.005-7.158,10.529-13.926,16.534-20.237c5.95-6.254,12.367-12.058,19.187-17.369C145.757,271.4,141.35,271.182,136.93,271.182z"></path>
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path d="M325.393,133.094c-2.509-6.271-5.831-12.13-9.857-17.433c-13.657-17.988-35.257-29.633-59.535-29.633 s-45.878,11.645-59.535,29.635c-4.026,5.303-7.348,11.162-9.857,17.433c-3.421,8.559-5.325,17.883-5.325,27.649 c0,9.765,1.904,19.089,5.325,27.648c2.509,6.271,5.831,12.13,9.857,17.433c13.657,17.988,35.257,29.634,59.535,29.634 s45.878-11.646,59.535-29.636c4.026-5.303,7.348-11.162,9.857-17.433c3.421-8.559,5.325-17.882,5.325-27.648 S328.814,141.653,325.393,133.094z"></path>
                                                                                                </g>
                                                                                            </g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path d="M391.768,390.252c-4.11-31.402-18.901-59.488-40.594-80.489c-5.137-4.971-10.656-9.547-16.515-13.672 c-6.044-4.256-12.444-8.04-19.149-11.288c-12.892-6.246-26.905-10.528-41.647-12.457v111.953c0,9.863-7.997,17.86-17.86,17.86 c-9.864,0-17.86-7.998-17.86-17.86V272.346c-14.743,1.929-28.755,6.211-41.648,12.457c-6.705,3.249-13.105,7.032-19.149,11.288 c-5.859,4.126-11.38,8.702-16.515,13.672c-21.695,21-36.485,49.087-40.594,80.489c-0.764,5.846-1.163,11.807-1.163,17.86 c0,9.863,7.997,17.86,17.86,17.86h238.14c9.864,0,17.86-7.998,17.86-17.86C392.933,402.059,392.534,396.098,391.768,390.252z"></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                                <h4>{{ organizationCount.employee_count }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                                <span class="font-primary d-flex align-items-center">
                                                                                    <span class="f-w-500" style="color:#7366ff">{{ organizationCount.department_count }} Departments</span>
                                                                                </span>

                                                                                <span class="font-primary d-flex align-items-center mt-1">
                                                                                    <span class="f-w-500" style="color:#7366ff">{{ organizationCount.designation_count }} Designation</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </router-link>

                                                    </div>

                                                    <div class="col-xl-6 box-col-6 col-lg-12 col-md-6" v-if="hasViewPermission('view_ticket')">
                                                        <router-link to="/hr/ticket/list">
                                                            <div class="card small-widget">
                                                                <div class="card-header card-no-border pb-4">
                                                                    <div class="header-top">
                                                                        <h5>Assignments</h5>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body pt-0">
                                                                    <div class="user-list">
                                                                        <div class="d-flex align-items-center" style="gap:10px;">
                                                                            <div class="user-icon primary">
                                                                                <div class="user-box">
                                                                                    <svg class="stroke-icon svg-fill" height="30px" width="30px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve" fill="#000000">

                                                                                        <g id="SVGRepo_iconCarrier">

                                                                                            <g>
                                                                                                <path class="st0 fill-danger" d="M92.574,294.24V124.336H43.277C19.449,124.336,0,144.213,0,168.467v206.44 c0,24.254,19.449,44.133,43.277,44.133h62v45.469c0,3.041,1.824,5.777,4.559,6.932c2.736,1.154,5.957,0.486,8.023-1.641 l49.844-50.76h106.494c23.828,0,43.279-19.879,43.279-44.133v-0.061H172.262C128.314,374.846,92.574,338.676,92.574,294.24z"></path>
                                                                                                <path class="st0 fill-success" d="M462.717,40H172.26c-27.105,0-49.283,22.59-49.283,50.197v204.037c0,27.61,22.178,50.199,49.283,50.199 h164.668l75.348,76.033c2.399,2.442,6.004,3.172,9.135,1.852c3.133-1.322,5.176-4.434,5.176-7.887v-69.998h36.131 c27.106,0,49.283-22.59,49.283-50.199V90.197C512,62.59,489.822,40,462.717,40z M369.156,280.115H195.92v-24.316h173.236V280.115z M439.058,204.129H195.92v-24.314h243.138V204.129z M439.058,128.143H195.92v-24.315h243.138V128.143z"></path>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                                <h4>{{ ticketCount.ticket_count }}</h4><span class="font-primary f-12 f-w-500"></span>

                                                                                <span class="font-primary d-flex align-items-center">
                                                                                    <span class="f-w-500 text-success">{{ ticketCount.ticket_closed_count}} Finished</span>
                                                                                </span>
                                                                                <span class="font-primary d-flex align-items-center mt-1">
                                                                                    <span class="f-w-500 text-danger">{{ ticketCount.ticket_open_count  }} Unfinished</span>
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </router-link>

                                                    </div>

                                                </div>

                                                <!-- Table -->
                                                <div class="row">
                                                    <div class="col-xl-12 box-col-12 col-md-12" v-if="hasViewPermission('view_projects')">
                                                        <div class="card small-widget">
                                                            <div class="card-header card-no-border pb-4">
                                                                <div class="header-top">
                                                                    <h5>Team Work Overview</h5>
                                                                </div>
                                                            </div>
                                                            <div class="card-body pt-0">
                                                                <div class="table-responsive datatable-vue">
                                                                    <b-table striped hover :items="transformedData" :fields="showTable" show-empty>
                                                                        <template #cell(index)="data">
                                                                            {{ data.index + 1 }}
                                                                        </template>
                                                                    </b-table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="col-xl-5 box-col-12 xl-100">
                                                <div class="row">

                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4 mt-md-2 mt-lg-4 mt-xl-0">

                                                        <div class="card small-widget" v-if="hasViewPermission('view_projects')">
                                                            <router-link to="/project/list">
                                                                <div class="card-body secondary"><span class="f-light">Initiatives</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ operationProjectCount }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">

                                                                        <svg class="stroke-icon svg-fill" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px">
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 6C3.25 4.45831 4.48029 3.26447 6.00774 2.50075C7.58004 1.7146 9.69967 1.25 12 1.25C14.3003 1.25 16.42 1.7146 17.9923 2.50075C19.5197 3.26447 20.75 4.45831 20.75 6V18C20.75 19.5417 19.5197 20.7355 17.9923 21.4992C16.42 22.2854 14.3003 22.75 12 22.75C9.69967 22.75 7.58004 22.2854 6.00774 21.4992C4.48029 20.7355 3.25 19.5417 3.25 18V6ZM4.75 6C4.75 5.33255 5.31057 4.52639 6.67856 3.84239C8.00168 3.18083 9.88205 2.75 12 2.75C14.118 2.75 15.9983 3.18083 17.3214 3.84239C18.6894 4.52639 19.25 5.33255 19.25 6C19.25 6.66745 18.6894 7.47361 17.3214 8.15761C15.9983 8.81917 14.118 9.25 12 9.25C9.88205 9.25 8.00168 8.81917 6.67856 8.15761C5.31057 7.47361 4.75 6.66745 4.75 6ZM4.75 18C4.75 18.6674 5.31057 19.4736 6.67856 20.1576C8.00168 20.8192 9.88205 21.25 12 21.25C14.118 21.25 15.9983 20.8192 17.3214 20.1576C18.6894 19.4736 19.25 18.6674 19.25 18V14.7072C18.8733 15.0077 18.4459 15.2724 17.9923 15.4992C16.42 16.2854 14.3003 16.75 12 16.75C9.69967 16.75 7.58004 16.2854 6.00774 15.4992C5.55414 15.2724 5.12675 15.0077 4.75 14.7072V18ZM19.25 8.70722V12C19.25 12.6674 18.6894 13.4736 17.3214 14.1576C15.9983 14.8192 14.118 15.25 12 15.25C9.88205 15.25 8.00168 14.8192 6.67856 14.1576C5.31057 13.4736 4.75 12.6674 4.75 12V8.70722C5.12675 9.00772 5.55414 9.27245 6.00774 9.49925C7.58004 10.2854 9.69967 10.75 12 10.75C14.3003 10.75 16.42 10.2854 17.9923 9.49925C18.4459 9.27245 18.8733 9.00772 19.25 8.70722Z"></path>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </div>

                                                        <div class="card small-widget" v-if="hasViewPermission('view_clients')">
                                                            <router-link to="/crm/client/list">
                                                                <div class="card-body secondary"><span class="f-light">Client</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ operationClientCount }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">

                                                                        <svg class="stroke-icon svg-fill" viewBox="-1.12 -1.12 18.24 18.24" height="30px" width="30px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#dc3545">
                                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                                                                                <path d="M16,12.5C16,11.837 15.737,11.201 15.268,10.732C14.799,10.263 14.163,10 13.5,10C11.447,10 8.553,10 6.5,10C5.837,10 5.201,10.263 4.732,10.732C4.263,11.201 4,11.837 4,12.5C4,14.147 4,15 4,15L16,15C16,15 16,14.147 16,12.5ZM3,13L0,13C0,13 0,12.147 0,10.5C0,9.837 0.263,9.201 0.732,8.732C1.201,8.263 1.837,8 2.5,8L6.536,8C6.754,8.376 7.031,8.714 7.355,9L6.5,9C4.567,9 3,10.567 3,12.5L3,13ZM10,3C11.656,3 13,4.344 13,6C13,7.656 11.656,9 10,9C8.344,9 7,7.656 7,6C7,4.344 8.344,3 10,3ZM6.126,6.997C6.084,6.999 6.042,7 6,7C4.344,7 3,5.656 3,4C3,2.344 4.344,1 6,1C7.026,1 7.932,1.516 8.473,2.302C7.022,2.903 6,4.333 6,6C6,6.344 6.044,6.679 6.126,6.997Z"></path>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </div>

                                                        <div class="card small-widget" v-if="hasViewPermission('view_grievance')">
                                                            <router-link to="/hr/grievance/list">
                                                                <div class="card-body secondary"><span class="f-light">Grievance</span>
                                                                    <div class="d-flex gap-1" style="gap:8px">
                                                                        <h4> {{ grievanceCount.grievance_count }}</h4>
                                                                        <div class="d-flex flex-column" style="gap:8px; margin-top: 34px;">
                                                                            <span class="f-14 f-w-500 text-success">{{ grievanceCount.grievance_open_count }} Open</span>
                                                                            <span class="f-14 f-w-500 text-danger">{{ grievanceCount.grievance_close_count }} Closed</span>
                                                                        </div>

                                                                    </div>
                                                                    <div class="bg-gradient">

                                                                        <svg class="stroke-icon svg-fill" fill="#dc3545" viewBox="-2 0 19 19" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px">
                                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path d="M14.032 5.286v7.276a1.112 1.112 0 0 1-1.108 1.108H8.75l-1.02 1.635a.273.273 0 0 1-.503 0l-1.02-1.635h-4.13a1.112 1.112 0 0 1-1.109-1.108V5.286a1.112 1.112 0 0 1 1.108-1.108h10.848a1.112 1.112 0 0 1 1.108 1.108zM8.206 11.34a.706.706 0 1 0-.706.705.706.706 0 0 0 .706-.705zm-1.26-1.83a.554.554 0 1 0 1.108 0V6.275a.554.554 0 1 0-1.108 0z"></path>
                                                                            </g>
                                                                        </svg>

                                                                    </div>
                                                                </div>

                                                            </router-link>

                                                        </div>

                                                        <div class="card small-widget" v-if="hasViewPermission('view_accounts')">
                                                            <router-link to="/finance-and-accounts/account/requisition/list">

                                                                <div class="card-body secondary"><span class="f-light">Requisition</span>
                                                                    <div class="d-flex gap-1" style="gap:8px">
                                                                        <h4>{{ requisitionCount.requisition_count }}</h4>
                                                                        <div class="d-flex flex-column" style="gap:8px; margin-top: 34px;">
                                                                            <span class="f-14 f-w-500 text-success">{{ requisitionCount.requisition_aprv_count }} Approved</span>
                                                                            <span class="f-14 f-w-500 text-info">{{ requisitionCount.requisition_pending_count }} Pending</span>
                                                                            <span class="f-14 f-w-500 text-warning">{{ requisitionCount.requisition_due_count }} Due</span>
                                                                            <span class="f-14 f-w-500 text-danger">{{ requisitionCount.requisition_rej_count }} Rejected</span>
                                                                        </div>

                                                                    </div>
                                                                    <div class="bg-gradient">

                                                                        <svg class="stroke-icon svg-fill" height="30px" width="30px" fill="#dc3545" viewBox="0 0 24 24" id="receipt-check" data-name="Line Color" xmlns="http://www.w3.org/2000/svg">
                                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <polyline id="secondary" points="10 10 12 12 16 8" style="fill: none; stroke: #51bb25; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline>
                                                                                <path id="primary" d="M21,4V18.5a2.5,2.5,0,0,1-5,0V16H5V4A1,1,0,0,1,6,3H20A1,1,0,0,1,21,4ZM16,18.5V16H3v2.5A2.5,2.5,0,0,0,5.5,21h13A2.5,2.5,0,0,1,16,18.5Z" style="fill: none; stroke: #dc3545; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path>
                                                                            </g>
                                                                        </svg>

                                                                    </div>
                                                                </div>
                                                            </router-link>

                                                        </div>

                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4 mt-md-2 mt-lg-4 mt-xl-0">

                                                        <div class="card small-widget" v-if="hasViewPermission('view_vendor_list')">
                                                            <router-link to="/procurement/vendor/list">
                                                                <div class="card-body warning"><span class="f-light">Vendors</span>
                                                                    <div class="d-flex align-items-end gap-1">
                                                                        <h4>{{ operationVendorCount }}</h4><span class="font-primary f-12 f-w-500"></span>
                                                                    </div>
                                                                    <div class="bg-gradient">

                                                                        <svg class="stroke-icon svg-fill" fill="#dc3545" height="30px" width="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-2.5 -2.5 40.70 40.70" xml:space="preserve">
                                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <g>
                                                                                    <path d="M11.558,10.767c0-3.473,2.815-6.29,6.289-6.29c3.476,0,6.289,2.817,6.289,6.29c0,3.475-2.813,6.29-6.289,6.29 C14.373,17.057,11.558,14.243,11.558,10.767z M35.667,22.607l-0.879-5.27c-0.158-0.954-0.961-1.754-1.896-1.984 c-0.836,0.74-1.932,1.191-3.136,1.191c-1.203,0-2.3-0.452-3.135-1.191c-0.938,0.229-1.739,1.03-1.897,1.984l-0.021,0.124 c-0.522-0.503-1.17-0.881-1.868-1.052c-1.33,1.176-3.072,1.896-4.987,1.896s-3.657-0.72-4.987-1.896 c-0.698,0.171-1.346,0.549-1.868,1.052l-0.021-0.124c-0.158-0.954-0.962-1.754-1.897-1.984c-0.835,0.74-1.932,1.191-3.135,1.191 c-1.204,0-2.3-0.452-3.136-1.191c-0.936,0.229-1.738,1.03-1.896,1.984l-0.879,5.27c-0.189,1.131,0.596,2.057,1.741,2.057h7.222 l-0.548,3.283c-0.3,1.799,0.948,3.271,2.771,3.271H24.48c1.823,0,3.071-1.475,2.771-3.271l-0.548-3.283h7.222 C35.071,24.662,35.855,23.738,35.667,22.607z M29.755,15.762c2.184,0,3.954-1.77,3.954-3.954c0-2.183-1.771-3.954-3.954-3.954 s-3.953,1.771-3.953,3.954C25.802,13.992,27.574,15.762,29.755,15.762z M5.938,15.762c2.183,0,3.953-1.77,3.953-3.954 c0-2.183-1.771-3.954-3.953-3.954c-2.184,0-3.954,1.771-3.954,3.954C1.984,13.992,3.755,15.762,5.938,15.762z"></path>
                                                                                </g>
                                                                            </g>
                                                                        </svg>

                                                                    </div>
                                                                </div>

                                                            </router-link>

                                                        </div>

                                                        <router-link to="/hr/leave-management/leave-requests">
                                                            <div class="card small-widget" v-if="hasViewPermission('view_leave_requests')">
                                                                <!-- <div class="card-header card-no-border pb-4">
                                                                        <h6 class="f-light">Leave</h6>
                                                                    </div> -->
                                                                <div class="card-body">
                                                                    <span class="f-light">Leave</span>
                                                                    <div class="categories-list">
                                                                        <div class="list-style">
                                                                            <div class="list-image"><img src="../../assets/images/dashboard/leave.png"></div>
                                                                            <div>
                                                                                <h6 class="mb-0">Total</h6><span class="f-light f-12 f-w-500">{{ leaveCount.leave_count }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="list-style">
                                                                            <div class="list-image"><img src="../../assets/images/dashboard/leave-approve.png"></div>
                                                                            <div>
                                                                                <h6 class="mb-0">Approved</h6><span class="f-light f-12 f-w-500">{{ leaveCount.leave_apv_count }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="list-style">
                                                                            <div class="list-image"><img src="../../assets/images/dashboard/leave-pending.png"></div>
                                                                            <div>
                                                                                <h6 class="mb-0">Pending</h6><span class="f-light f-12 f-w-500">{{ leaveCount.leave_pending_count }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="list-style">
                                                                            <div class="list-image"><img src="../../assets/images/dashboard/leave-rejected.png"></div>
                                                                            <div>
                                                                                <h6 class="mb-0">Pending</h6><span class="f-light f-12 f-w-500">{{ leaveCount.leave_rej_count }}</span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </router-link>

                                                        <router-link to="/eod/list">
                                                            <div class="card small-widget" v-if="hasViewPermission('view_eod')">
                                                                <!-- <div class="card-header card-no-border pb-4">
                                                                        <h6 class="f-light">EOD Reports</h6>
                                                                    </div> -->
                                                                <div class="card-body">
                                                                    <span class="f-light">EOD Reports</span>
                                                                    <div class="categories-list">
                                                                        <div class="list-style">
                                                                            <div class="list-image"><img src="../../assets/images/dashboard/eod.png"></div>
                                                                            <div>
                                                                                <h6 class="mb-0">Total</h6><span class="f-light f-12 f-w-500">{{ eodCount.eod_count }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="list-style">
                                                                            <div class="list-image"><img src="../../assets/images/dashboard/eod-approve.png"></div>
                                                                            <div>
                                                                                <h6 class="mb-0">Approved</h6><span class="f-light f-12 f-w-500">{{ eodCount.eod_approved_count }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="list-style">
                                                                            <div class="list-image"><img src="../../assets/images/dashboard/eod-pending.png"></div>
                                                                            <div>
                                                                                <h6 class="mb-0 ">Pending</h6><span class="f-light f-12 f-w-500">{{ eodCount.eod_pending_count }}</span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </router-link>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </b-tab>

                                </b-tabs>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="user_access.is_vendor == true">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div style="display: flex; flex-direction: row; gap: 40px;  align-items: center;">

                                <img style="height: 100px; width: 100px; border-radius: 100%;" v-if="details.additional_info[0]" class="" :src="host + details.additional_info[0].photo" alt="Profile Photo">
                                <div style="margin-top: 15px; display: flex; flex-direction: column;">
                                    <h5>{{ details.user_object.first_name }} {{ details.user_object.last_name }}</h5>
                                    <h7>Email: {{ details.user_object.email }}</h7>
                                    <!-- <h7>Phone: {{ details.phone }}</h7>
                        <h7>Company: {{ details.company }}</h7>
                        <h7>Address: {{ details.address }}</h7> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row" v-if="user_access.is_candidate == true">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div style="display: flex; flex-direction: row; gap: 40px;  align-items: center;">

                                <img style="height: 100px; width: 100px; border-radius: 100%;" v-if="details.additional_info[0]" class="" :src="host + details.additional_info[0].photo" alt="Profile Photo">
                                <div style="margin-top: 15px; display: flex; flex-direction: column;">
                                    <h5>{{ details.user_object.first_name }} {{ details.user_object.last_name }}</h5>
                                    <h7>Email: {{ details.user_object.email }}</h7>
                                    <!-- <h7>Phone: {{ details.phone }}</h7>
                                                            <h7>Company: {{ details.company }}</h7>
                                                            <h7>Address: {{ details.address }}</h7> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Reply Create Modal -->
            <b-modal id="modal-details" ref="modal" :title="detail_title" hide-footer>

                <div v-if="this.calendarDetails.type === 'project'">
                    <div class="row">
                        <div class="col">
                            <b-form-group label="Project" label-for="name-input">
                                <b-form-input v-model="calendarDetails.title" placeholder="" disabled></b-form-input>
                            </b-form-group>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <b-form-group label="Start Date" label-for="name-input">
                                <datepicker placeholder="" input-class="datepicker-here form-control digits" v-model="calendarDetails.start_date" disabled></datepicker>
                            </b-form-group>
                        </div>
                        <div class="col-sm-6">
                            <b-form-group label="Deadline" label-for="name-input">
                                <datepicker placeholder="" input-class="datepicker-here form-control digits" v-model="calendarDetails.end_date" disabled></datepicker>
                            </b-form-group>
                        </div>

                    </div>
                </div>

            </b-modal>

        </div>

    </div>
    <div v-else style="width: 100%;">
        <div class="container-fluid">
            <div class="row flex-md-row-reverse">
                <div class="loader-wrapper">
                    <div class="loader-index">
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import error_400Vue from '../../errors/error_400.vue';
import {
    myHost
} from '../../_helpers/host'
import {
    CalendarView,
    CalendarViewHeader,
    CalendarMathMixin
} from 'vue-simple-calendar';
import Datepicker from 'vuejs-datepicker';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import "./default.css";
import "./style.css";

var primary = localStorage.getItem('primary_color') || '#3c8a24';
var secondary = localStorage.getItem('secondary_color') || '#818284';

export default {
    components: {
        CalendarView,
        CalendarViewHeader,
        Datepicker
    },
    mixins: [CalendarMathMixin],
    data() {
        const currentDate = new Date();
        return {
            month: currentDate.getMonth(),
            year: currentDate.getFullYear(),
            seriesData: [30, 40, 45, 50, 49, 60, 70, 91, 85, 77, 66, 55, 47, 36, 45, 60, 70, 65, 78, 89, 90, 93, 95, 97, 98, 99, 100, 95, 90, 85],
            host: myHost.host,
            currentTime: new Date().toLocaleTimeString(),
            tabIndex: 0,
            permissions: JSON.parse(localStorage.getItem('permissions')),
            user_access: JSON.parse(localStorage.getItem('user')),
            fetchedData: {},
            // chartOptions: {
            //     chart: {
            //         height: 100,
            //         type: 'line',

            //         toolbar: {
            //             show: false
            //         }
            //     },
            //     series: [{
            //         name: 'Personal Growth',
            //         data: [30, 40, 45, 50, 49, 60, 70, 91]
            //     }],
            //     xaxis: {
            //         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            //     }
            // },
            // Calendar
            format: 'yyyy-MM-dd',

            newevent: {
                title: '',
                startDate: '',
                endDate: ''
            },
            showEventTimes: true,
            showDate: new Date(),
            events: {
                projects: [{
                        startDate: '2024-06-17',
                        endDate: '2024-06-21',
                        title: 'projects event 1',
                    },
                    {
                        startDate: '2024-06-21T00:00:01',
                        endDate: '2024-06-21T23:59:59',
                        title: 'projects event 2',
                        classes: "orange",
                    },
                    {
                        startDate: '2024-05-23',
                        endDate: '2024-05-23',
                        title: 'projects event 3',
                    },
                    {
                        startDate: '2024-06-25T00:00:00',
                        endDate: '2024-06-25T23:59:59',
                        title: 'projects event 4',
                    },
                    {
                        startDate: '2024-05-29T00:00:00',
                        endDate: '2024-05-29T23:59:59',
                        title: 'projects event 5',
                    },
                ],
                meeting: [{
                        startDate: '2024-06-17T10:00:00',
                        endDate: '2024-06-17T12:00:00',
                        title: 'Meeting event 1',
                    },
                    {
                        startDate: '2024-06-21T00:00:01',
                        endDate: '2024-06-21T23:59:59',
                        title: 'Meeting event 2',
                    },
                    {
                        startDate: '2024-05-23',
                        endDate: '2024-05-23',
                        title: 'Meeting event 3',
                    },
                    {
                        startDate: '2024-06-25T00:00:00',
                        endDate: '2024-06-25T23:59:59',
                        title: 'Meeting event 4',
                    },
                    {
                        startDate: '2024-05-29T00:00:00',
                        endDate: '2024-05-29T23:59:59',
                        title: 'Meeting event 5',
                    },

                ]

            },
            // Calendar end

            apexRiskchart: {
                options: {
                    chart: {
                        height: 350,
                        type: 'radialBar',
                        offsetY: -10,
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -135,
                            endAngle: 135,
                            inverseOrder: true,
                            hollow: {
                                margin: 5,
                                size: '60%',
                                image: require('../../assets/images/dashboard-2/radial-image.png'),
                                imageWidth: 140,
                                imageHeight: 140,
                                imageClipped: false,
                            },
                            track: {
                                opacity: 0.4,
                                colors: primary,
                            },
                            dataLabels: {
                                enabled: false,
                                enabledOnSeries: undefined,
                                formatter: function (val) {
                                    return val + '%';
                                },
                                textAnchor: 'middle',
                                distributed: false,
                                offsetX: 0,
                                offsetY: 0,
                                style: {
                                    fontSize: '14px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fill: ['#2b2b2b'],
                                },
                            },
                        },
                    },
                    labels: ['Completed'],
                    colors: [primary],
                    stroke: {
                        dashArray: 15,
                        strokecolor: ['#ffffff'],
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.15,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100],
                            gradientToColors: ['#a927f9'],
                            type: 'horizontal',
                        },
                    },
                },
                series: [],
            },
            apexDashboard: {
                options: {
                    chart: {
                        width: 685,
                        height: 240,
                        type: 'area',
                        toolbar: {
                            show: false,
                        },
                    },
                    colors: [primary, secondary],
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                    },
                    xaxis: {
                        type: 'category',
                        low: 0,
                        offsetX: 0,
                        offsetY: 0,
                        show: false,
                        categories: [
                            'Jan',
                            'Feb',
                            'Mar',
                            'Apr',
                            'May',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Oct',
                            'Nov',
                            'Dec',
                            'Jan',
                        ],
                        labels: {
                            low: 0,
                            offsetX: 0,
                            show: false,
                        },
                        axisBorder: {
                            low: 0,
                            offsetX: 0,
                            show: false,
                        },
                    },
                    yaxis: {
                        low: 0,
                        offsetX: 0,
                        offsetY: 0,
                        show: false,
                        labels: {
                            low: 0,
                            offsetX: 0,
                            show: false,
                        },
                        axisBorder: {
                            low: 0,
                            offsetX: 0,
                            show: false,
                        },
                    },
                    markers: {
                        strokeWidth: 3,
                        colors: '#ffffff',
                        strokeColors: [primary, secondary],
                        hover: {
                            size: 6,
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.5,
                            stops: [0, 80, 100]
                        }
                    },
                    legend: {
                        show: false,
                    },
                    tooltip: {
                        x: {
                            format: 'MM'
                        },
                    },
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0,
                            bottom: -15,
                            top: -40
                        }
                    },
                },
                series: [{
                        name: 'series1',
                        data: [6, 20, 15, 40, 18, 20, 18, 23, 18, 35, 30, 55, 0],
                    },
                    {
                        name: 'series2',
                        data: [2, 22, 35, 32, 40, 25, 50, 38, 42, 28, 20, 45, 0],
                    },
                ],
            },
            message: '',

            locations: {
                latitude: 0,
                longitude: 0,
            },
            detail_title: '',
            calendarDetails: {
                type: '',
                id: '',
                title: '',
                start_date: '',
                end_date: '',

            },

            currentDate: new Date()

        };
    },

    mounted() {

        this.$store.dispatch("employee/fetchMyDetails")

        let user_access = JSON.parse(localStorage.getItem('user'));

        if (user_access.is_employee === true) {
            this.$store.dispatch("punch/fetchLatestTimeSheet");
            this.$store.dispatch("dashboard/fetchMyWorkspaceCount");
            this.$store.dispatch("dashboard/fetchCompletionRatio").then(() => {
                this.apexRiskchart.series.push(this.completionPercentage.completion_percentage)
            });
        }

        this.startClock();
        if (localStorage.getItem('reloaded') == 'no') {

            localStorage.setItem('reloaded', 'yes')
            this.$router.go(0)
        }
    },
    created() {
        let user_access = JSON.parse(localStorage.getItem('user'));

        if (user_access.is_employee === true) {
            this.$store.dispatch("dashboard/fetchCompletionSubTask").then(() => {
                this.chartOptions()
            });
            this.$store.dispatch("dashboard/fetchActivitySummary")
            this.$store.dispatch("dashboard/fetchCalendarData").then(() => {
                combinedEvents()
            });
        }

        this.$getLocation({})
            .then(coordinates => {
                // console.log(coordinates)
                this.locations.latitude = coordinates.lat
                this.locations.longitude = coordinates.lng
            })
        // .catch(error => alert(error));
    },
    computed: {
        ...mapState({
            myDashboardCount: state => state.dashboard.myWorkspaceCount,
            operationAssetCount: state => state.dashboard.operationAssetCount.asset_count,
            operationProjectCount: state => state.dashboard.operationProjectCount.project_count,
            operationLeadCount: state => state.dashboard.operationLeadClientCount.leads_count,
            operationClientCount: state => state.dashboard.operationLeadClientCount.client_count,

            operationVendorContactCount: state => state.dashboard.operationVendorCount.vendor_contact_count,
            operationVendorCount: state => state.dashboard.operationVendorCount.vendor_count,

            eodCount: state => state.dashboard.operationEODCount,
            leaveCount: state => state.dashboard.operationLeaveCount,

            grievanceCount: state => state.dashboard.operationGrievanceCount,
            ticketCount: state => state.dashboard.operationTicketCount,

            requisitionCount: state => state.dashboard.operationRequisitionCount,

            organizationCount: state => state.dashboard.operationOrganizationCount,

            details: state => state.employee.my_detail,
            timeSheet: state => state.punch.time_sheet_latest,
            completionPercentage: state => state.dashboard.completion_ratio,

            subtask_completion: state => state.dashboard.completion_subtask_day,
            activity_summary_list: state => state.dashboard.activity_summary,
            calendarData: state => state.dashboard.calendar_data,

            loading: state => state.dashboard.submitLoading,
            error: state => state.dashboard.submitError,

        }),

        transformedData() {
            const fields = [{
                key: 'member_name',
                label: 'Member Name'
            }, ];
            const dates = [];

            // format date
            function formatDate(dateStr) {
                const date = new Date(dateStr);
                const day = date.getDate();
                const month = date.toLocaleString('default', {
                    month: 'short'
                });
                return `${day} ${month}`;
            }

            // all unique dates
            this.activity_summary_list.forEach(member => {
                member.last_8_days.forEach(day => {
                    const formattedDate = formatDate(day.date);
                    if (!dates.includes(formattedDate)) {
                        dates.push(formattedDate);
                    }
                });
            });

            // Add date fields dynamically
            dates.forEach((date, index) => {
                fields.push({
                    key: date,
                    label: date,
                    variant: index === 0 ? 'secondary' : null
                    // variant: index === dates.length - 1 ? 'secondary' : null
                });
            });

            this.showTable = fields;

            // Transform the data to the desired structure
            return this.activity_summary_list.map(member => {
                const row = {
                    member_name: member.member_name
                };
                member.last_8_days.forEach(day => {
                    const formattedDate = formatDate(day.date);
                    row[formattedDate] = `${day.completed_subtask_count}/${day.subtask_count}`;
                });
                return row;
            });
        },

        daysInMonth() {
            return new Date(this.year, this.month + 1, 0).getDate();
        },
        daysArray() {
            return Array.from({
                length: this.daysInMonth
            }, (v, k) => k + 1);
        },
        // chartOptions() {
        //     return {
        //         chart: {
        //             height: 100,
        //             type: 'line',
        //             toolbar: {
        //                 show: false
        //             }
        //         },
        //         series: [{
        //             name: 'Subtask Completed',
        //             data: this.subtask_completion,
        //         }],
        //         xaxis: {
        //             // categories: this.daysArray,

        //             labels: {
        //                 categories: this.daysArray,
        //                 formatter: function (value) {
        //                     return value;
        //                 }
        //             }

        //         },

        //     };
        // },
        chartOptions() {
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const monthName = monthNames[this.month];

            return {
                chart: {
                    height: 200,
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            const day = this.daysArray[config.dataPointIndex];
                            const value = this.subtask_completion[config.dataPointIndex];
                            alert(`${day} ${monthName} Subtask completed ${value}`);
                        }
                    }
                },
                series: [{
                    name: 'Subtask Completed',
                    data: this.subtask_completion,
                }],
                xaxis: {
                    labels: {
                        categories: this.daysArray,
                        formatter: function (value) {
                            return ''; // Hide x-axis labels
                        }
                    }
                },
                tooltip: {
                    custom: function ({
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w
                    }) {
                        const day = this.daysArray[dataPointIndex];
                        const value = series[seriesIndex][dataPointIndex];
                        return `<div class='m-2'>Date: ${day} ${monthName}</div><div class='m-2'>Subtask completed: ${value}</div>`;
                    }.bind(this) // Ensure the function has access to the Vue component's context
                }
            };
        },

        formattedDate() {
            const options = {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            };
            return this.currentDate.toLocaleDateString('en-GB', options);
        },
        combinedEvents() {

            // // Merge projects and meetings into one array with classes
            // const projects = this.calendarData.project.map(event => ({
            //     ...event,
            //     startDate: this.formatTime(event.start_date),
            //     endDate: this.formatTime(event.end_date),
            //     classes: 'purple'
            // }));

            // const meetings = this.calendarData.meeting.map(event => ({
            //     ...event,
            //     startDate: this.formatTime(event.start_date),
            //     endDate: this.formatTime(event.end_date),
            //     classes: 'green'
            // }));

            // // Combine projects and meetings
            // return [...projects, ...meetings];

            // const combined = [...this.events.projects, ...this.events.meeting];
            const combined = [...this.calendarData.project];

            // Format dates to exclude seconds
            return combined.map(event => ({
                ...event,
                startDate: event.start_date,
                endDate: event.deadline,
                classes: 'purple',
                type: 'project'

            }));

        }

    },
    methods: {
        // Calendar
        formatTime(datetime) {
            // Parse the date
            const date = new Date(datetime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            if (datetime.includes('T')) {

                return `${year}-${month}-${day}T${hours}:${minutes}`;
            } else {
                return `${year}-${month}-${day}`;
            }
        },
        setShowDate(d) {
            this.showDate = d;

        },
        onDrop(event, date) {
            this.message = `You dropped ${event.id} on ${date.toLocaleDateString()}`;
            // Determine the delta between the old start date and the date chosen,
            // and apply that delta to both the start and end date to move the event.
            const eLength = this.dayDiff(event.startDate, date);
            event.originalEvent.startDate = this.addDays(event.startDate, eLength);
            event.originalEvent.endDate = this.addDays(event.endDate, eLength);
        },
        addEvent() {
            this.events.push({
                title: this.newevent.title,
                startDate: this.newevent.startDate,
                endDate: this.newevent.endDate,
            });
            alert(JSON.stringify(this.events))
            this.newevent.title = '',
                this.newevent.startDate = '',
                this.newevent.endDate = '';
        },

        onClickEvent(event) {
            const data = event.originalEvent;
            this.calendarDetails.id = data.id
            this.calendarDetails.type = data.type
            this.calendarDetails.start_date = data.startDate
            this.calendarDetails.end_date = data.endDate
            this.calendarDetails.title = data.title
            if (data.type === 'project') {
                this.detail_title = 'Project Details'
            }

            this.$nextTick(() => {
                this.$bvModal.show('modal-details')
            })

            this.message = `You clicked: ${event.title}`;
        },

        // Calendar End

        attend() {
            if (this.locations.longitude == 0 && this.locations.latitude == 0) {
                Swal.fire({
                    title: "Error!",
                    text: 'You must enable location permission first!',
                    icon: "error"
                });
            } else {
                this.$store.dispatch("punch/addAttende", this.locations).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }

                })
            }

        },

        startClock() {
            setInterval(() => {
                this.currentTime = this.getCurrentTime();
            }, 1000);
        },
        getCurrentTime() {
            return new Date().toLocaleTimeString([], {
                hour12: true
            });
        },
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['text-success']
            } else {

            }
        },
        hasViewPermission(code) {
            if (!this.permissions.includes(code)) {
                return false;
            }
            if (!this.fetchedData[code]) {
                switch (code) {
                    case 'view_assets':
                        this.$store.dispatch("dashboard/fetchOperationAssetCount");
                        break;
                    case 'view_projects':
                        this.$store.dispatch("dashboard/fetchOperationProjectCount");
                        break;
                    case 'view_leads':
                    case 'view_clients':
                        this.$store.dispatch("dashboard/fetchOperationLeadClientCount");
                        break;
                    case 'view_vendor_contacts':
                    case 'view_vendor_list':
                        this.$store.dispatch("dashboard/fetchOperationVendorCount");
                        break;
                    case 'view_eod':
                        this.$store.dispatch("dashboard/fetchOperationEODCount");
                        break;
                    case 'view_ticket':
                        this.$store.dispatch("dashboard/fetchOperationTicketCount");
                        break;

                    case 'view_leave_type':
                    case 'view_leave_requests':
                        this.$store.dispatch("dashboard/fetchOperationLeaveCount");
                        break;

                    case 'view_grievance':
                        this.$store.dispatch("dashboard/fetchOperationGrievanceCount");
                        break;
                    case 'view_ticket':
                        this.$store.dispatch("dashboard/fetchOperationTicketCount");
                        break;

                    case 'view_accounts':
                        this.$store.dispatch("dashboard/fetchOperationRequsitionCount");
                        break;

                    case 'view_department':
                    case 'view_designation':
                    case 'view_employee':
                        this.$store.dispatch("dashboard/fetchOperationOrganizationCount");
                        break;

                    default:
                        break;
                }
                this.fetchedData[code] = true;
            }
            return true;
        }
    },

};
</script>

<style scoped>
/* @import "../../../node_modules/vue-simple-calendar/dist/css/default.css";
@import "../../../node_modules/vue-simple-calendar/dist/style.css"; */

/* @import "./style.css";
@import "./default.css"; */

* {
    box-sizing: border-box;
}

.small-widget {
    overflow: hidden;
}

.warning .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(255, 170, 5, .1) 19.06%, rgba(255, 170, 5, 0) 79.03%) !important;
}

.success .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(84, 186, 74, .1) 19.06%, rgba(84, 186, 74, 0) 79.03%) !important;
}

.secondary .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(255, 51, 100, .1) 19.06%, rgba(255, 51, 100, 0) 79.03%) !important;
}

.secondary .svg-fill {
    fill: #F73A6A;
}

.success .svg-fill {
    fill: #54ba4a;
}

.warning .svg-fill {
    fill: #ffaa05;
}

.primary .svg-fill {
    fill: #7366ff;
}

.user-list .user-icon.primary {
    border: 1px solid #7366ff;
}

.user-list .user-icon {
    border-radius: 100%;
    display: inline-block;
}

.user-list .user-icon.primary .user-box {
    background: #e8e6ff;
}

.user-list .user-icon .user-box {
    margin: 3px;
    width: 47px;
    height: 47px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-widget .bg-profile {
    width: 125px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    left: -12px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}

.small-widget .profile-info {
    display: flex;
    flex-direction: column;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}

.small-widget .bg-gradient {
    width: 66px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    right: -12px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}

.categories-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.categories-list .list-style {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

}

.list-image img {
    height: 30px;
    width: 30px;
}

.btn-submit {
    color: white;
    font-weight: 500;
    background-color: #563EE0;
    border-color: #563EE0 !important
}

.btn-submit:hover {
    color: white;
    background-color: #F42F65;
    border-color: #F42F65 !important
}

.profile_body {

    /* background: linear-gradient(120deg, #ffebfc8e 10%, #d7e0ffa1 30%, #FFFFFF 60%); */
    background-image: url('../../assets/images/dashboard/profile-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;

}

.showcase2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    gap: 20px;
}

/* .image-container {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ffffff;
    border: 2px solid #5B81DE;
} */

.rounded-image {
    width: 125px;
    height: 130px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;

}

/* Style.CSS */

/* Default.CSS */
</style>
