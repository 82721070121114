<template>
<div>
    <Breadcrumbs main="My Workspace" submain="Requsition" title="My Requisitions" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="width: 100%">
                        <div style="max-width: fit-content;">
                            <router-link to="/my-workspace/requisition-create">
                                <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                    <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                        <i class="fa fa-light fa-plus"></i>
                                        <h7>Add New Requisition</h7>
                                    </div>
                                </button>
                            </router-link>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group style="width: 340px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="statusSelected || search_term || start_date || end_date">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="requisition_list" :fields="tablefields" :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <!-- /finance-and-accounts/account/requisition/view/ -->
                                    <template #cell(requisition_id)="data">
                                        <span>
                                            <router-link :to="`/my-workspace/requisition-view/${data.item.id}`" class="link-style">
                                                {{ data.item.requisition_id }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(title)="data">
                                        <span>
                                            {{ data.item.title }}
                                        </span>
                                    </template>

                                    <template #cell(raised_by)="data">
                                        <span>
                                            {{ data.item.raised_by.first_name }} {{ data.item.raised_by.last_name }}
                                        </span>
                                    </template>

                                    <template #cell(refering_to)="data">
                                        <span>
                                            <div v-if="data.item.refering_to !== null && data.item.refering_to.length > 0">
                                                <div class="customers">
                                                    <ul>
                                                        <ul v-for="(member, index) in data.item.refering_to.slice(0,5)" :key="index">
                                                            <li v-if="member.photo" class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" :src="host + member.photo" alt="" data-original-title=""></li>
                                                            <li v-else class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" src="../../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                        </ul>
                                                        <li class="d-inline-block mr-2" v-if="data.item.refering_to.length > 5">
                                                            <p class="f-12">+ {{ data.item.refering_to.length - 5 }}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p>N/A</p>
                                            </div>
                                        </span>
                                    </template>
                                    <template #cell(approved_by)="data">
                                        <div>
                                            <p style="max-width: 250px;">{{ data.item.approved_by ? data.item.approved_by.full_name : 'N/A' }}</p>
                                        </div>
                                    </template>

                                    <template #cell(total)="data">
                                        <div class="d-flex flex-column" style="width: 150px;">
                                            <span class="font-weight-bold">Total: {{ data.item.grand_total }}</span>
                                            <span><span class="text-success font-weight-bold">Paid:</span> {{ data.item.paid_amount }}</span>
                                            <span><span class="text-danger font-weight-bold">Due:</span> {{ data.item.due_amount }}</span>
                                        </div>
                                    </template>

                                    <template #cell(status)="row">
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'APPROVED'">
                                            <b-badge pill variant="success">{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'PENDING'">
                                            <b-badge pill variant="primary">{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'DUE'">
                                            <b-badge pill variant="danger">{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(requisition_for)="row">
                                        <span v-if="row.item.project == null">
                                            <b-badge pill variant="success">Individual</b-badge>
                                        </span>
                                        <span v-else>
                                            <b-badge pill variant="primary">Project</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span>
                                            <!-- <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRequisition(data.item.id)" title="Delete"></feather> -->
                                            <router-link :to="`/my-workspace/requisition-view/${data.item.id}`">
                                                <feather type="eye" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="View"></feather>
                                            </router-link>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
import host, {
    myHost
} from '../../../../_helpers/host';

export default {
    components: {
        Datepicker,
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'requisition_id',
                    label: 'REQ-ID',
                    sortable: true
                }, {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'total',
                    label: 'Grand Total',
                    sortable: true
                },
                {
                    key: 'raised_by',
                    label: 'Raised By',
                    sortable: true
                },
                {
                    key: 'refering_to',
                    label: 'Reffered To',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false
                },

                {
                    key: 'requisition_for',
                    label: 'Requisition',
                    sortable: false
                },
                {
                    key: 'approved_by',
                    label: 'Approved By',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            host: myHost.host,
            initialLoad: true,
            filterShow: false,
            start_date: null,
            end_date: null,
            statusSelected: '',
            statuses: [{
                    id: 1,
                    name: 'Pending',
                    value: 'PENDING'
                },
                {
                    id: 2,
                    name: 'Approved',
                    value: 'APPROVED'
                },
                {
                    id: 3,
                    name: 'Due',
                    value: 'DUE',
                }
            ],
            filter: null,
            delete_value: null,
            selectedStatus: null,
            update_id: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState({
            requisition_list: state => state.requisition.my_requisition,
            loading: state => state.requisition.submitLoading,
            error: state => state.requisition.submitError,
            count: state => state.requisition.my_requisition_count
        }),

        formattedOptions() {
            return [{
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        }
    },

    watch: {
        filter(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("requisition/fetchMyRequisition", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }

        },

        start_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;
                    if (this.start_date !== null) {
                        this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("requisition/fetchMyRequisition", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false; 
                            
                        });
                    } else {
                        this.$store.dispatch("requisition/fetchMyRequisition", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false; 
                            
                        });
                    }
                }

            }

        },

        end_date(newVal, oldVal) {
            if (!this.initialLoad) {
                if (!this.isModifyingDate) {
                    this.isModifyingDate = true;

                    if (this.end_date !== null) {
                        this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                        this.$store.dispatch("requisition/fetchMyRequisition", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false; 
                        });
                    } else {
                        this.$store.dispatch("requisition/fetchMyRequisition", {
                            perPage: this.perPage,
                            currentPage: 1,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            status: this.statusSelected,
                            search_term: this.filter
                        }).then(() => {
                            this.currentPage = 1
                            this.totalRows = this.count;
                            this.initialLoad = false;
                            this.isModifyingDate = false; 
                        });
                    }
                }

            }

        },

        statusSelected(newVal, oldVal) {
            if (!this.initialLoad) {
                this.$store.dispatch("requisition/fetchMyRequisition", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }

        },
        currentPage(newCurrentPage) {

            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/my-workspace/my-requisition',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("requisition/fetchMyRequisition", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/my-workspace/my-requisition',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("requisition/fetchMyRequisition", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,

                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        }
    },

    mounted() {

        if (this.initialLoad) {
            if (!this.$route.query.page || !this.$route.query.perPage) {

                this.$router.replace({
                    path: this.$route.path,
                    query: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });

                this.fetchInitialEOD();
            } else {

                this.currentPage = parseInt(this.$route.query.page);
                this.perPage = parseInt(this.$route.query.perPage);

                this.fetchInitialEOD();
            }
        }
    },

    methods: {
        fetchInitialEOD() {
            this.$store.dispatch("requisition/fetchMyRequisition", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        },
        clearFilter() {
            this.filterShow = false,
                this.filter = null,
                this.statusSelected = '',
                this.start_date = null,
                this.end_date = null
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        deleteRequisition(id) {
            this.delete_value = id
        },
        getStatus(status, id) {
            this.update_id = id
            this.selectedStatus = status

        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {

            this.$store.dispatch("requisition/updateRequisitionStatus", {
                id: this.update_id,
                status: this.selectedStatus
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        handleDeleteOk() {
            this.$store.dispatch("requisition/deleteRequisition", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>

<style scoped>
.img-30 {
    width: 30px !important;
    height: 30px !important;
}
</style>
